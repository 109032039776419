import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {NotifierService} from "angular-notifier";
import {ServiceService} from "../../../service.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {WebappService} from '../webapp.service';
import {JwtHelperService} from '@auth0/angular-jwt';

@Component({
  selector: 'app-manage-products',
  templateUrl: './manage-products.component.html',
  styleUrls: ['./manage-products.component.css']
})
export class ManageProductsComponent implements OnInit {
  rows = [];
  isAdd=true;
  tempTag = ""
  tempData = {
      sku:"",
    name:"",
    description:"",
    slug:"",
    image:{
      id:1,
      thumbnail:"",
      original:""
    },
    price:0,
    category: {_id: "", slug: ""},
    brand: {_id: ""},
    sale_price:0,
    variations:[],
    gallery:[],
    tags:[],
    meta:[
        {
            id: 1,
            title: "Product Details",
            content: ""
        },
        {
            id: 2,
            title: "Additional Information",
            content: ""
        }
        ],
  };
  initialData = {
    name:"",
      sku:"",
    description:"",
    slug:"",
    image:{
      id:1,
      thumbnail:"",
      original:""
    },
    price:0,
    category: {_id: "", slug: ""},
    brand: {_id: ""},
    sale_price:0,
    variations:[],
    gallery:[],
    tags:[],
    meta:[
        {
        id: 1,
        title: "Product Details",
        content: ""
        },
        {
            id: 2,
            title: "Additional Information",
            content: ""
        }],
  }
  allCategories = []
  allCategoriesInitial = []
  allBrands = []

  private notifier: NotifierService;

  constructor(private serviceClass: WebappService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.updateTableData();

      this.serviceClass.getAllCategories().subscribe(
          data => {
              this.allCategories = data.data
              this.allCategoriesInitial = data.data
          }
      );
      this.serviceClass.getAllBrands().subscribe(
          data => {
              this.allBrands = data
          }
      );
  }

  updateTableData(){
    this.serviceClass.getAllProducts().subscribe(
        data => {
          this.rows = data;
        }
    );
  }

  onChange(file) {
    this.serviceClass.upload(file).subscribe(res => {
      console.log(res);
    });
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  resetThePage() {
    window.location.reload();
  }

  openWidgetForAdd() {
    this.isAdd = true;
    this.tempData = this.initialData;
    document.getElementById("dataModelBtn").click()
  }

  openWidgetForEditing(data: any) {
    this.isAdd = false;
    this.tempData = data;
    document.getElementById("dataModelBtn").click()
  }

  deleteProduct(data:any) {
    if(confirm("Do you want to delete this item? ("+data.name+")")) {
      this.serviceClass.deleteProduct(data).subscribe(
          data => {
            this.updateTableData();
            this.showNotification("success", "Deleted successfully!")
          }
      );
    }
  }

  updateChanges() {
    if(this.isAdd){
      this.serviceClass.addProduct(this.tempData).subscribe(
          data => {
              this.tempData = this.initialData;
            this.updateTableData();
            document.getElementById("dataModelBtnClose").click()
            this.showNotification("success", "Added successfully!")
          }
      );
    } else {
      this.serviceClass.updateProduct(this.tempData).subscribe(
          data => {
              this.tempData = this.initialData;
            this.updateTableData();
            document.getElementById("dataModelBtnClose").click()
            this.showNotification("success", "Updated successfully!")
          }
      );
    }
  }


  // @ts-ignore
  cloudinary = cloudinary.createUploadWidget(this.serviceClass.cloudinaryArray[Number(localStorage.getItem('imageCount')) % this.serviceClass.numOfCloudinaryAccounts], (error, result) => {
        if(result.info.thumbnail_url != null) {
          this.tempData.image.original = result.info.secure_url;
          this.tempData.image.thumbnail = result.info.secure_url;
        }
      }
  )

    uploadPrimaryImage() {
        if(localStorage.getItem('imageCount')){
            this.cloudinary.open();
            localStorage.setItem('imageCount', (Number(localStorage.getItem('imageCount')) + 1).toString())
        } else {
            localStorage.setItem('imageCount','1')
            this.cloudinary.open();
        }
    }

    // @ts-ignore
    galleryUpload = cloudinary.createUploadWidget(this.serviceClass.cloudinaryArray[Number(localStorage.getItem('imageCount')) % this.serviceClass.numOfCloudinaryAccounts], (error, result) => {
            if(result.info.thumbnail_url != null) {
                this.tempData.gallery.push({
                    id:this.tempData.gallery.length + 1,
                    thumbnail: result.info.secure_url,
                    original: result.info.secure_url
                })
            }
        }
    )
    uploadGalleryImage() {
        if(localStorage.getItem('imageCount')){
            this.galleryUpload.open();
            localStorage.setItem('imageCount', (Number(localStorage.getItem('imageCount')) + 1).toString())
        } else {
            localStorage.setItem('imageCount','1')
            this.galleryUpload.open();
        }
    }

    removeFromGallery(galleryImage: any) {
        if (confirm("Do you want to remove this image?")) {
            this.tempData.gallery = this.tempData.gallery.filter(value => value !== galleryImage)
        }
    }

    onCategoryChange(categoryElement:any) {
        let allCategories = this.allCategories;
        this.tempData.category = allCategories.filter(value=> value.slug === categoryElement)[0]
        this.allCategories = this.allCategoriesInitial;
    }

    onBrandChange(categoryElement:any) {
        this.tempData.brand = this.allBrands.find(value=> value._id === categoryElement)
    }

    setSeoUrl(value: any) {
        this.tempData.slug = value.toLowerCase().split(' ').join('-')
    }

    addTags() {
        this.tempTag = this.tempTag.toLowerCase();
        this.tempData.tags.push({
            id: this.tempData.tags.length + 1,
            name: this.tempTag.charAt(0).toUpperCase() + this.tempTag.slice(1),
            slug: this.tempTag
        })
        this.tempTag = "";
    }

    deleteTag(tagsElement) {
        this.tempData.tags = this.tempData.tags.filter(value => value != tagsElement).map((value, index)=> { return {...value, id: index}})
    }
}

import {Routes} from '@angular/router';
import {MainOrdersComponent} from "./OrderManagement/main-orders/main-orders.component";
import {UpdateOrderComponent} from "./OrderManagement/update-order/update-order.component";
import {AddOrderComponent} from "./OrderManagement/add-order/add-order.component";
import {Order_creationComponent} from "./Reports/order_creation/order_creation.component";
import {OrderConfirmationComponent} from "./Reports/order-confirmation/order-confirmation.component";
import {OrderPendingComponent} from "./Reports/order-pending/order-pending.component";
import {OrderDeliveredComponent} from "./Reports/order-delivered/order-delivered.component";
import {OrderReturnedComponent} from "./Reports/order-returned/order-returned.component";
import {OrderCanceledComponent} from "./Reports/order-canceled/order-canceled.component";
import {DispatchBaseonDeliveryTypeComponent} from "./Reports/dispatch-baseon-delivery-type/dispatch-baseon-delivery-type.component";
import {DeliveryBasedonDateComponent} from "./Reports/delivery-basedon-date/delivery-basedon-date.component";
import {OrderAccuracyComponent} from "./Reports/order-accuracy/order-accuracy.component";
import {ItemWiseComponent} from "./Reports/item-wise/item-wise.component";
import {UploadingSheetComponent} from "./Reports/uploading-sheet/uploading-sheet.component";
import {OrderDetailsStickerPasteComponent} from "./Reports/order-details-sticker-paste/order-details-sticker-paste.component";
import {AddressStickerPrintComponent} from "./Reports/address-sticker-print/address-sticker-print.component";
import {DispatchDateCountComponent} from "./Reports/dispatch-to-delivered-date-count/dispatch-date-count.component";
import {DispatchToReturnDateCountComponent} from "./Reports/dispatch-to-return-date-count/dispatch-to-return-date-count.component";
import {ReturnReportForReturnedDateComponent} from "./Reports/return-report-for-returned-date/return-report-for-returned-date.component";
import {CashAcceptedCheckingComponent} from "./cash-accepted-checking/cash-accepted-checking.component";
import {CheckProntoParcelAcceptComponent} from "./Reports/check-pronto-parcel-accept/check-pronto-parcel-accept.component";
import {ItemManagementComponent} from "./Management/item-management/item-management.component";
import {EmployeeManagementComponentAdmin} from "./Management/employee-management/employee-management.component";
import {CheckReturnOrdersComponent} from "./Reports/check-return-orders/check-return-orders.component";
import {DispatchOrderComponent} from "./OrderManagement/dispatch-order/dispatch-order.component";
import {CodUpdateComponent} from "./cod-update/cod-update.component";
import {ReturnOrderComponent} from './OrderManagement/return-order/return-order.component';
import {BulkOrderDeliveredComponent} from './OrderManagement/bulk-order-delivered/bulk-order-delivered.component';
import {AuthGuard} from '../auth.guard';
import {WarrantyManagerComponent} from './warranty-manager/warranty-manager.component';
import {ProfileComponent} from './profile/profile.component';
import {BackupDownloadComponent} from './backup-download/backup-download.component';
import {CategoryManagerComponent} from './ChatBot/category-manager/category-manager.component';
import {PromotionManagerComponent} from './ChatBot/promotion-manager/promotion-manager.component';
import {ManufacturerManagerComponent} from './ChatBot/manufacturer-manager/manufacturer-manager.component';
import {ItemsManagerComponent} from './ChatBot/items-manager/items-manager.component';
import {DisableChatComponent} from './ChatBot/disable-chat/disable-chat.component';
import {CurrentDispatchedListComponent} from './Reports/current-dispatched-list/current-dispatched-list.component';
import {DispatchTrackerComponent} from './OrderManagement/dispatch-tracker/dispatch-tracker.component';
import {ManageProductsComponent} from './WebApp/manage-products/manage-products.component';
import {ManageCategoriesComponent} from './WebApp/manage-categories/manage-categories.component';
import {ManageBrandsComponent} from './WebApp/manage-brands/manage-brands.component';
import {OrderManagerComponent} from './WebApp/order-manager/order-manager.component';
import {ManageUserComponent} from './payroll/manage-user/manage-user.component';
import {ItemTypesComponent} from './payroll/item-types/item-types.component';
import {PaymentsComponent} from './payroll/manage-user/Common/payments/payments.component';
import {PaysheetReportComponent} from './payroll/paysheet-report/paysheet-report.component';
import {CategoryWiseDeliveryComponent} from './Reports/category-wise-delivery/category-wise-delivery.component';

export const DashboardRoutes: Routes = [
  {
    path: 'main',
    component: MainOrdersComponent,
    data: {
      title: 'Main Page',
      urls: [
        {title: 'Main Page', url: '/main'},
        {title: 'Main Page'}
      ]
    },
    canActivate:[AuthGuard]
  },
  {
    path: 'order_details',
    component: UpdateOrderComponent,
    data: {
      title: 'Order Details',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Order Details'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'add_order',
    component: AddOrderComponent,
    data: {
      title: 'Add order',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Add order'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/order_creation',
    component: Order_creationComponent,
    data: {
      title: 'Order Creation Report',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'General Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/order_confirmation',
    component: OrderConfirmationComponent,
    data: {
      title: 'Order Confirmation Report',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Confirmation Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/order_pending',
    component: OrderPendingComponent,
    data: {
      title: 'Order Pending Report',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Pending Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/order_delivered',
    component: OrderDeliveredComponent,
    data: {
      title: 'Order Delivered Report',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Delivered Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/order_returned',
    component: OrderReturnedComponent,
    data: {
      title: 'Order Returned Report',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Returned Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/order_canceled',
    component: OrderCanceledComponent,
    data: {
      title: 'Order Canceled Report',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Canceled Report'}
      ]
    }
  },

  {
    path: 'reports/dispatch-with-deliveryType',
    component: DispatchBaseonDeliveryTypeComponent,
    data: {
      title: 'Dispatch Base On Delivery Type Report',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Dispatch Base On Delivery Type Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/delivery-baseOn-date',
    component: DeliveryBasedonDateComponent,
    data: {
      title: 'Delivery Base On Date',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Delivery Base On Date'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/accuracy-rate',
    component: OrderAccuracyComponent,
    data: {
      title: 'Success Rate Report',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Success Rate Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/category-wise-delivery',
    component: CategoryWiseDeliveryComponent,
    data: {
      title: 'Category Wise Delivery',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Category Wise Delivery'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/item-wise',
    component: ItemWiseComponent,
    data: {
      title: 'Item Wise Report',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Item Wise Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/pronto-uploadSheet',
    component: UploadingSheetComponent,
    data: {
      title: 'Uploading Sheet Report',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Uploading Sheet Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/all-oderDetails',
    component: OrderDetailsStickerPasteComponent,
    data: {
      title: 'All Order Details',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'All Order Details'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/current-dispatched-orders',
    component: CurrentDispatchedListComponent,
    data: {
      title: 'Current Dispatched List',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Current Dispatched List'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/dispatch-to-delivered-date-count',
    component: DispatchDateCountComponent,
    data: {
      title: 'Dispatch To Delivered Date Count',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Dispatch To Delivered Date Count'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'reports/dispatch-to-return-date-count',
    component: DispatchToReturnDateCountComponent,
    data: {
      title: 'Dispatch To Returned Date Count',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Dispatch To Returned Date Count'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'dispatch/address-print',
    component: AddressStickerPrintComponent,
    data: {
      title: 'Address Print',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Address Print'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'return/based-on-returned-date',
    component: ReturnReportForReturnedDateComponent,
    data: {
      title: 'Return Day Report',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Return Day Report'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'checker/pronto-cash',
    component: CashAcceptedCheckingComponent,
    data: {
      title: 'Cash Accept Checker',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Cash Accept Checker'}
      ]
    },
    canActivate:[AuthGuard]
  },

  {
    path: 'checker/pronto-parcel-accept',
    component: CheckProntoParcelAcceptComponent,
    data: {
      title: 'Parcel Accept Checker',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Parcel Accept Checker'}
      ]
    },
    canActivate:[AuthGuard]
  },
  {
    path: 'manage/item',
    component: ItemManagementComponent,
    data: {
      title: 'Item Management',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Item Management'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'manage/employee',
    component: EmployeeManagementComponentAdmin,
    data: {
      title: 'Employee Management',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Employee Management'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'report/category-wise-delivery',
    component: CheckReturnOrdersComponent,
    data: {
      title: 'Category Wise Delivery',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Check Return'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'report/check-return-orders',
    component: CheckReturnOrdersComponent,
    data: {
      title: 'Check Return',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Check Return'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'order/dispatch/now',
    component: DispatchOrderComponent,
    data: {
      title: 'Dispatch Order',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Dispatch Order'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'order/return/now',
    component: ReturnOrderComponent,
    data: {
      title: 'Return Order',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Return Order'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'order/dispatch-tracker',
    component: DispatchTrackerComponent,
    data: {
      title: 'Dispatch Tracker',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Dispatch Tracker'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'cod/update',
    component: CodUpdateComponent,
    data: {
      title: 'Update COD',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Update COD'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'order/bulk/delivered',
    component: BulkOrderDeliveredComponent,
    data: {
      title: 'Bulk Order Delivery',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Bulk Order Delivery'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'order/warranty-manager',
    component: WarrantyManagerComponent,
    data: {
      title: 'Warranty Manager',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Warranty Manager'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      title: 'Profile',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Profile'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'download/backup',
    component: BackupDownloadComponent,
    data: {
      title: 'BackUp Downloader',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'BackUp Downloader'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'chat-bot/category-manager',
    component: CategoryManagerComponent,
    data: {
      title: 'Bot Category Manager',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Bot Category Manager'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'chat-bot/items-manager',
    component: ItemsManagerComponent,
    data: {
      title: 'Bot Items Manager',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Bot Items Manager'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'chat-bot/manufacturer-manager',
    component: ManufacturerManagerComponent,
    data: {
      title: 'Bot Manufacturer Manager',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Bot Manufacturer Manager'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'chat-bot/promotion-manager',
    component: PromotionManagerComponent,
    data: {
      title: 'Bot Promotion Manager',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Bot Promotion Manager'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'chat-bot/disableChat',
    component: DisableChatComponent,
    data: {
      title: 'Disable Auto Reply On Bot',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Disable Auto Reply On Bot'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'webapp/products',
    component: ManageProductsComponent,
    data: {
      title: 'Manage Products',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Manage Products'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'webapp/categories',
    component: ManageCategoriesComponent,
    data: {
      title: 'Manage Categories',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Manage Categories'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'webapp/brands',
    component: ManageBrandsComponent,
    data: {
      title: 'Manage Brands',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Manage Brands'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'webapp/orders',
    component: OrderManagerComponent,
    data: {
      title: 'Manage Orders',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Manage Orders'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'payroll/users',
    component: ManageUserComponent,
    data: {
      title: 'Manage Payroll Users',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Manage Payroll Users'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'payroll/items',
    component: ItemTypesComponent,
    data: {
      title: 'Payroll Items',
      urls: [
        {title: 'Main Page', url: '/admin/main'},
        {title: 'Payroll Items'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'payroll/user/profile',
    component: PaymentsComponent,
    data: {
      title: 'Payroll User Profile',
      urls: [
        {title: 'Payroll', url: '/admin/payroll/users'},
        {title: 'Payroll User Profile'}
      ]
    },
    canActivate:[AuthGuard]
  }
  ,
  {
    path: 'payroll/user/paysheet-report',
    component: PaysheetReportComponent,
    data: {
      title: 'PaySheet Report',
      urls: [
        {title: 'Payroll', url: '/admin/payroll/users'},
        {title: 'PaySheet Report'}
      ]
    },
    canActivate:[AuthGuard]
  }

];

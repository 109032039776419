<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="col-12">
                    <div class="table-responsive">
                        <button class="btn btn-outline-primary" (click)="openWidgetForAdd()" style="float: right">Add</button>
                        <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
                            <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">SEO Unique</th>
                                <th scope="col">Primary Image</th>
                                <th scope="col">Tools</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr  *ngFor="let indexElement of rows">
                                <td>{{indexElement.name}}</td>
                                <td>{{indexElement.slug}}</td>
                                <td><img class="tableImage" src="{{indexElement.image.original}}"  onerror="this.src='https://res.cloudinary.com/unik-wear/image/upload/v1624732467/99776312_s_iep0jb.jpg';"/></td>
                                <td>
                                    <div class="row">
                                        <div class="col-6">
                                            <a (click)="openWidgetForEditing(indexElement)" class="pointer"><i class="fa fa-edit" ></i></a>
                                        </div>
                                        <div class="col-6">
                                            <a (click)="deleteProduct(indexElement)" class="pointer"><i class="fa fa-trash" style="color: red"></i></a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#dataModel" id="dataModelBtn" style="display: none">
</button>

<!-- Modal -->
<div class="modal fade" id="dataModel" tabindex="-1" role="dialog" aria-labelledby="dataModel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="exampleModalLabel">{{isAdd? "Add" : "Edit"}} Brands</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Product Name</label>
                    <input [(ngModel)]="tempData.name" class="form-control"  ngModel type="text" value="{{tempData.name}}"/>
                </div>
                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Unique URL SEO</label>
                    <input [(ngModel)]="tempData.slug" class="form-control"  ngModel type="text" value="{{tempData.slug}}"/>
                </div>

                <hr/>
                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Primary Image</label>
                    <div class="row">
                        <div class="col-6 m-t-10 m-b-10">
                            <a (click)="uploadPrimaryImage()"> <img  class="imageSize pointer" src="{{tempData.image.original}}" onerror="this.src='https://res.cloudinary.com/unik-wear/image/upload/v1624732467/99776312_s_iep0jb.jpg';" /></a>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"  id="dataModelBtnClose" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="updateChanges()">{{isAdd? "Add" : "Update"}} changes</button>
            </div>
        </div>
    </div>
</div>

<notifier-container></notifier-container>

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class PayrollService {

  // private url = 'http://localhost:8080/api/';
  // private url = 'https://unikwear-payroll-app.herokuapp.com/api/';
  private url = 'https://payroll-api.unikwear.lk/api/';

  constructor(private serviceHttp: HttpClient, private http: HttpClient) {}

  isPayrollAllowed(){
    const helper = new JwtHelperService();
    const dataValues = helper.decodeToken(localStorage.getItem('user_details')).rows[0];
    return ['awsnbwithanage','asela.jaye@gmail.com','wathsala','Samanali','lakshitha.acc@unikwear.lk','shiranthi@unikwear.lk'].includes(dataValues.empEmail);
  }

  // --------------------------------------------------------------------------------------------------------
  payrollGetAllUsers(): Observable<any> {
    return this.serviceHttp.get<any>(this.url + 'payrollUsers');
  }

  payrollUserAdd(userDetails: any): Observable<any> {
    return this.serviceHttp.post<any>(this.url + 'payrollUsers' , userDetails);
  }
  payrollUserUpdate(userDetails: any): Observable<any> {
    return this.serviceHttp.put<any>(this.url + 'payrollUsers/'+userDetails.id , userDetails);
  }


  // --------------------------------------------------------------------------------------------------------
  payrollGetAllItems(): Observable<any> {
    return this.serviceHttp.get<any>(this.url + 'payrollItems');
  }

  payrollItemsAdd(itemDetails: any): Observable<any> {
    return this.serviceHttp.post<any>(this.url + 'payrollItems' , itemDetails);
  }
  payrollItemsUpdate(itemDetails: any): Observable<any> {
    return this.serviceHttp.put<any>(this.url + 'payrollItems/'+itemDetails.id , itemDetails);
  }



  // --------------------------------------------------------------------------------------------------------
  payrollGetAllPayment(): Observable<any> {
    return this.serviceHttp.get<any>(this.url + 'payrollPayments');
  }

  payrollGetPayment(id): Observable<any> {
    return this.serviceHttp.get<any>(this.url + 'payrollPayments/userId/'+id);
  }

  payrollPaymentAdd(itemDetails: any): Observable<any> {
    return this.serviceHttp.post<any>(this.url + 'payrollPayments' , itemDetails);
  }
  payrollPaymentUpdate(itemDetails: any): Observable<any> {
    return this.serviceHttp.put<any>(this.url + 'payrollPayments/'+itemDetails.id , itemDetails);
  }

  payrollPaymentDelete(itemDetails: any): Observable<any> {
    return this.serviceHttp.delete<any>(this.url + 'payrollPayments/'+itemDetails._id);
  }


  // --------------------------------------------------------------------------------------------------------
  payrollGetAllSheets(userId): Observable<any> {
    return this.serviceHttp.get<any>(this.url + 'payrollPaySheet/userId/'+userId);
  }
  payrollSheetsAdd(itemDetails: any): Observable<any> {
    return this.serviceHttp.post<any>(this.url + 'payrollPaySheet' , itemDetails);
  }
  payrollSheetsDelete(itemDetails: any): Observable<any> {
    return this.serviceHttp.delete<any>(this.url + 'payrollPaySheet/'+itemDetails._id);
  }


}

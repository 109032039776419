<div class="label-container">
    <div class="header">
        <img src="assets/images/logo/wide-logo-black.png" alt="Clicky OM Logo" class="logo">
        <div class="order-no">Order No : {{orderId}}</div>
    </div>
    <div class="section from">
        <h2>From</h2>
        <p><b>Name :</b> Unik Wear Batik</p>
        <p><b>Address :</b> No 245, Maya Mawatha, Kiribathgoda, Sri Lanka.</p>
        <p><b>Contact No :</b> 071 5 190 899</p>
    </div>
    <div class="section to">
        <h2>To</h2>
        <p><b>Name :</b> {{clientName}}</p>
        <p><b>Address :</b> {{addressNumber}}, {{firstLine}}, {{secondLine}}, {{city}}</p>
        <p><b>Contact No :</b> {{phoneNumber}} / {{telephone}}</p>
    </div>
    <div class="cod">
        <p *ngIf="paymentStatus === 'paidWithoutDelivery'">COD : {{getDeliveryCost()}} LKR</p>
        <p *ngIf="paymentStatus === 'paid'">Paid</p>
        <p *ngIf="paymentStatus === 'visit'">Visit Order</p>
        <p *ngIf="paymentStatus === 'cod'">COD : {{total}} LKR</p>
        <p *ngIf="paymentStatus === ''">COD : {{total}} LKR</p>
        <ngx-barcode
                [bc-margin-bottom]="0"
                [bc-margin-top]="0"
                [bc-value]="codNumber"
                [bc-display-value]="false"></ngx-barcode>
        <p class="barcode-number">{{codNumber}}</p>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {PayrollService} from '../payroll.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as XLSX from 'xlsx';
import {JwtHelperService} from '@auth0/angular-jwt';

@Component({
  selector: 'app-paysheet-report',
  templateUrl: './paysheet-report.component.html',
  styleUrls: ['./paysheet-report.component.css']
})
export class PaysheetReportComponent implements OnInit {
  keyword = 'name';
  rows = [];
  filteredRows = [];

  confirmOrderAccess: any;
  deliveredOrderAccess: any;
  canceledOrderAccess: any;
  noteId: number;
  private notifier: NotifierService;

  allPaymentDetails: any = [];
  allItemList: any = [];

  startDate: any;
  endDate: any;
  isAdd: any=true;
  pEmpId: any="none";
  pEmpName: any="none";
  pEmpType: any="daily";
  basicSalaryPayment: any= 0;
  providentFundNo: any;

  constructor(public serviceClass: PayrollService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit(): void {
    window.scroll(0, 0);

    this.serviceClass.payrollGetAllPayment().subscribe(
        data => {
          this.allPaymentDetails = data;
          this.filteredRows = data;
        }
    );

    this.serviceClass.payrollGetAllItems().subscribe(
        data => {
          this.allItemList = data;
          this.allItemList.push({
            createdAt: "2021-09-25T07:14:27.109Z",
            name: "1",
            price: 1,
            updatedAt: "2021-09-25T07:14:27.109Z",
            __v: 0,
            _id: "1"})
        }
    );

    this.updatePayrollDetails();
  }


  updatePayrollDetails(): void {
    this.serviceClass.payrollGetAllUsers().subscribe(
        data => {
          this.rows = data;
          document.getElementById("modelClose").click();
        }
    );
  }


  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  addEmployee() {
    if (this.pEmpName != "none") {
      this.serviceClass.payrollUserAdd({name: this.pEmpName , type: this.pEmpType, monthlyPayment: this.basicSalaryPayment, providentFundNo: this.providentFundNo}).subscribe(
          data => {
            this.updatePayrollDetails();
            this.pEmpName = "";
            this.providentFundNo = "";
          }
      );
    } else {
      this.showNotification("error", "Please select user details!");
    }
  }

  updateEmployeeDetails() {
    if (this.pEmpId != "none") {
      this.serviceClass.payrollUserUpdate({id: this.pEmpId, name: this.pEmpName, type: this.pEmpType, monthlyPayment:  this.basicSalaryPayment, providentFundNo: this.providentFundNo}).subscribe(
          data => {
            if (data.status) {
              this.updatePayrollDetails();
              this.showNotification("success", "Item Updated Successfully!");
            } else {
              this.showNotification("error", "There is a error. Please check and try again!");
            }
          }
      );
    } else {
      this.showNotification("error", "Please select user details!");
    }
  }

  setAccordionId(index: number) {
    if(index === 0){
      this.pEmpName = "";
      this.basicSalaryPayment = 0;
    }
    this.noteId = index;
  }

  exportAsExcel(): void {
    let currentTimeAndDate = new Date();
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.formatDate(currentTimeAndDate) + " - PaySheet report.xlsx");
  }


  formatDate(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }


  routeToSingleOrderPage(indexElement) {
    const windowHash = window.location.hash;
    const urlArray = windowHash.split("/");
    this.router.navigate(['/' + urlArray[1] + '/payroll/user/profile'], {queryParams: indexElement});
  }


  dateUpdate() {
    let startDateTemp = new Date(this.startDate);
    let endDateTemp = this.endDate === undefined ? new Date() :  new Date(this.endDate+"T23:59:59.542Z");
    this.filteredRows = this.allPaymentDetails.filter(value=> {
      let createdDate = new Date(value.date);
      return startDateTemp < createdDate && endDateTemp > createdDate;
    })
  }

  getItemCount(itemId, userId) {
    let paymentDetails = this.allPaymentDetails > this.filteredRows ? this.filteredRows : this.allPaymentDetails;
    return paymentDetails.map(value=> {
      if(value.userId === userId && value.itemTypeId === itemId){
        return value.itemCount;
      } else {
        return 0
      }
    }).reduce((a, b) => { return a + b; })
  }

  getItemTotalPricing(userId) {
    let paymentDetails = this.allPaymentDetails > this.filteredRows ? this.filteredRows : this.allPaymentDetails;
    return paymentDetails.map(value=> {
      if(value.userId === userId && (this.allItemList.find(itemDetails => itemDetails._id === value.itemTypeId) !== undefined )){
        return value.itemCount * (this.allItemList.find(itemDetails => itemDetails._id === value.itemTypeId)).price;
      } else {
        return 0
      }
    }).reduce((a, b) => { return a + b; })
  }

  calculate(userId, keyword){
    let totalValue = 0;
    this.filteredRows.filter(value => {
      if(value.type === keyword && value.userId === userId){
        totalValue += value.amount;
      }
    })
    return totalValue > 0 ? totalValue : 0;
  }
}

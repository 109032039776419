<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" id="bootstrap-css" rel="stylesheet"/>
<div id="invoice">
    <div class="invoice overflow-auto">
        <div style="min-width: 600px;">
            <br/><br/><br/><br/><br/><br/><br/><br/>
            <header>
                <!--        <div class="row">-->
                <!--          <div class="col">-->
                <!--            <img class="invoiceLogo" data-holder-rendered="true" src="assets/images/logo/wide-logo-black.png"/>-->
                <!--          </div>-->
                <!--          <div class="col company-details">-->
                <!--            <h2 class="name biggerFont">-->
                <!--              Unikwear.lk-->
                <!--            </h2>-->
                <!--            <div class="biggerFont">-->
                <!--              245,<br/>-->
                <!--              Maya Mawatha,<br/>-->
                <!--              Kiribathgoda,<br/>-->
                <!--              11600-->
                <!--            </div>-->
                <!--            <div class="biggerFont">071 519 0899</div>-->
                <!--            <div class="biggerFont">info@unikwear.lk</div>-->
                <!--          </div>-->
                <!--        </div>-->
            </header>
            <main>
                <div class="row contacts">
                    <div class="col invoice-to">
                        <div class="text-gray-light">Delivery to:</div>
                        <div class="to biggerFont">{{orderData.firstName}} {{orderData.lastName}}</div>
                        <div class="address biggerFont">{{orderData.address}},</div>
                        <div class="address biggerFont">{{orderData.city}},</div>
                        <div class="address biggerFont">{{orderData.zipCode}},</div>
                        <div class="address biggerFont">{{orderData.email}},</div>
                        <div class="email biggerFont">{{orderData.phoneNumber}}</div>
                    </div>
                    <div class="col invoice-details">
                        <h1 class="invoice-id">Web Order ID: {{orderData.orderId}}</h1>
                        <div class="date biggerFont">Date of Invoice: {{todayDate}}</div>
                    </div>
                </div>
                <table border="0" cellpadding="0" cellspacing="0">
                    <thead>
                    <tr>
                        <th class="text-left biggerFont">Name</th>
                        <th class="text-right biggerFont">Item Price</th>
                        <th class="text-right biggerFont">QTY</th>
                        <th class="text-right biggerFont">TOTAL</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr   *ngFor="let indexElement of orderData.items;">
                        <td class="text-left biggerFont">{{indexElement.name}}</td>
                        <td class="text-left biggerFont">{{indexElement.price}}.00 Lkr</td>
                        <td class="unit biggerFont">{{indexElement.quantity}}</td>
                        <td class="qty biggerFont">{{indexElement.itemTotal}}.00 Lkr</td>
                    </tr>

                    </tbody>
                    <tfoot>
                    <tr>
                        <td colspan="1"></td>
                        <td colspan="2" class="biggerFont">Delivery cost</td>
                        <td class="biggerFont">{{orderData.deliveryCost}}.00 LKR</td>
                    </tr>
<!--                    <tr *ngIf="discount > 0">-->
<!--                        <td colspan="2"></td>-->
<!--                        <td colspan="2" class="biggerFont">Discount</td>-->
<!--                        <td class="biggerFont">- {{discount}}.00 LKR</td>-->
<!--                    </tr>-->
                    <tr>
                        <td colspan="1"></td>
                        <td colspan="2"><h1>GRAND TOTAL</h1></td>
                        <td>{{orderData.total}}.00 LKR</td>
                    </tr>
                    </tfoot>
                </table>
                <h2 style="text-align: center">{{orderData.note}}</h2>
                <h2 style="text-align: center">Order Status: {{orderData.orderStatus}} | Payment Option: {{orderData.paymentOption}} | Payment Id: {{orderData.paymentId}} | Paid Amount: {{orderData.paidAmount}} | Payment Status: {{orderData.paymentStatus}} | Web Confirmation Status: {{orderData.webPaymentStatus}}</h2>
                <h1>Thank you!</h1>
                <!--        <div class="notices">-->
                <!--          <div>Tracking Code:</div>-->
                <!--          <div class="notice"><ngx-barcode [bc-height]="80"-->
                <!--                                           [bc-margin-bottom]="0"-->
                <!--                                           [bc-margin-top]="0"-->
                <!--                                           [bc-value]="codNumber"-->
                <!--                                           [bc-display-value]="true"-->
                <!--                                           [bc-width]="1.8"></ngx-barcode></div>-->
                <!--          <div class="notice1 biggerFont">*EXCHANGE IS POSSIBLE WITHING 7 DAYS OF PURCHASE WITH TAG, ITEMS NEED TO BE IN ORIGINAL CONDITION & ACCOMPLISHED WITH THE INVOICE.</div>-->
                <!--        </div>-->
            </main>
        </div>
        <div></div>
    </div>
</div>

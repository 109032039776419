import { Component, OnInit } from '@angular/core';
import {PayrollService} from '../../Admin/payroll/payroll.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-paysheet',
  templateUrl: './paysheet.component.html',
  styleUrls: ['./paysheet.component.css']
})
export class PaysheetComponent implements OnInit {
  params: any;
  constructor(private serviceClass: PayrollService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  ngOnInit() {
  }

  formatNumber(value){
    return new Intl.NumberFormat('en-IN', { style: "currency", currency: "LKR" }).format(Number(value))
  }

  getItemList() {
    return JSON.parse(this.params.itemWiseCount)
  }
}

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="col-12">
                    <div class="table-responsive">
                        <button class="btn btn-outline-primary" (click)="openWidgetForAdd()" style="float: right">Add</button>
                        <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
                            <thead>
                            <tr>
                                <th scope="col">SKU</th>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                                <th scope="col">Price</th>
                                <th scope="col">Sale Price</th>
                                <th scope="col">SEO Unique</th>
                                <th scope="col">Category</th>
                                <th scope="col">Brand</th>
                                <th scope="col">Tags</th>
                                <th scope="col">Primary Image</th>
                                <th scope="col">Gallery Image</th>
                                <th scope="col">Tools</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr  *ngFor="let indexElement of rows">
                                <td>{{indexElement.sku}}</td>
                                <td>{{indexElement.name}}</td>
                                <td>{{indexElement.description}}</td>
                                <td>{{indexElement.price}}</td>
                                <td>{{indexElement.sale_price}}</td>
                                <td>{{indexElement.slug}}</td>
                                <td>{{indexElement.category.name}}</td>
                                <td>{{indexElement.brand.name}}</td>
                                <td><span  *ngFor="let tagsElement of indexElement.tags" >{{tagsElement.name}}, </span></td>
                                <td><img class="tableImage" src="{{indexElement.image.original}}"  onerror="this.src='https://res.cloudinary.com/unik-wear/image/upload/v1624732467/99776312_s_iep0jb.jpg';"/></td>
                                <td>
                                    <img class="tableImage"  *ngFor="let galleryImage of indexElement.gallery" src="{{galleryImage.original}}" onerror="this.src='https://res.cloudinary.com/unik-wear/image/upload/v1624732467/99776312_s_iep0jb.jpg';" />
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-6">
                                            <a (click)="openWidgetForEditing(indexElement)" class="pointer"><i class="fa fa-edit" ></i></a>
                                        </div>
                                        <div class="col-6">
                                            <a (click)="deleteProduct(indexElement)" class="pointer"><i class="fa fa-trash" style="color: red"></i></a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#dataModel" id="dataModelBtn" style="display: none"></button>


<div class="modal fade" id="dataModel" tabindex="-1" role="dialog" aria-labelledby="dataModel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="exampleModalLabel">{{isAdd? "Add" : "Edit"}} Products</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">SKU</label>
                    <input [(ngModel)]="tempData.sku" class="form-control"  ngModel type="text" value="{{tempData.sku}}"/>
                </div>
                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Product Name</label>
                    <input [(ngModel)]="tempData.name" class="form-control"  ngModel type="text" value="{{tempData.name}}"/>
                </div>
                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Product Description</label>
                    <input [(ngModel)]="tempData.description" class="form-control"  ngModel type="text" value="{{tempData.description}}"/>
                </div>
                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Product Details</label>
                    <input [(ngModel)]="tempData.meta[0].content" class="form-control"  ngModel type="text" value="{{tempData.meta[0].content}}"/>
                </div>
                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Additional Information</label>
                    <input [(ngModel)]="tempData.meta[1].content" class="form-control"  ngModel type="text" value="{{tempData.meta[1].content}}"/>
                </div>
                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Unique URL SEO</label>
                    <input [(ngModel)]="tempData.slug" class="form-control"  ngModel type="text" value="{{tempData.slug}}"  (change)="setSeoUrl($event.target.value)"/>
                </div>
                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Product Price (LKR)</label>
                    <input [(ngModel)]="tempData.price" class="form-control"  ngModel type="number" value="{{tempData.price}}"/>
                </div>
                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Product Sale Price (LKR)</label>
                    <input [(ngModel)]="tempData.sale_price" class="form-control"  ngModel type="number" value="{{tempData.sale_price}}"/>
                </div>
                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Category</label>
                    <select [(ngModel)]="tempData.category.slug"  value="{{tempData.category.slug}}"  class="form-control" (change)="onCategoryChange($event.target.value)" >
                        <option  *ngFor="let categoryElements of allCategoriesInitial" value="{{categoryElements.slug}}" >{{categoryElements.name}}</option>
                    </select>
                </div>

                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Brand</label>
                    <select [(ngModel)]="tempData.brand._id" value="{{tempData.brand._id}}"  class="form-control"  (change)="onBrandChange($event.target.value)">
                        <option  *ngFor="let brandElement of allBrands" value="{{brandElement._id}}">{{brandElement.name}}</option>
                    </select>
                </div>
                <div class="col-12 m-t-10 m-b-10">
                    <div class="row">
                        <div class="col-7">
                            <label class="form-control-label">Add Tags</label>
                            <input [(ngModel)]="tempTag" class="form-control"  ngModel type="text" value="{{tempTag}}"/>
                        </div>
                        <div class="col-5">
                            <br/>
                            <button type="button" class="btn btn-success" (click)="addTags()">+</button>
                        </div>
                    </div>
                    <br/>
                    <div class="row">
                        <span class="label-tag" *ngFor="let tagsElement of tempData.tags" (click)="deleteTag(tagsElement)">{{tagsElement.name}} x</span>
                    </div>
                </div>

                <hr/>

                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Primary Image</label>
                    <div class="row">
                        <div class="col-6 m-t-10 m-b-10">
                            <a (click)="uploadPrimaryImage()"> <img  class="imageSize pointer" src="{{tempData.image.original}}" onerror="this.src='https://res.cloudinary.com/unik-wear/image/upload/v1624732467/99776312_s_iep0jb.jpg';" /></a>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Gallery Image</label>
                    <div class="row">
                        <div class="col-6 m-t-10 m-b-10"   *ngFor="let galleryImage of tempData.gallery">
                            <a (click)="removeFromGallery(galleryImage)"> <img class="imageSize pointer" src="{{galleryImage.original}}" onerror="this.src='https://res.cloudinary.com/unik-wear/image/upload/v1624732467/99776312_s_iep0jb.jpg';" /></a>
                        </div>
                        <div class="col-6 m-t-10 m-b-10">
                            <a (click)="uploadGalleryImage()"> <img class="imageSize pointer" src="https://res.cloudinary.com/unik-wear/image/upload/v1624734900/website/cloud-upload-a30f385a928e44e199a62210d578375a_rcgyjf.jpg" onerror="this.src='https://res.cloudinary.com/unik-wear/image/upload/v1624732467/99776312_s_iep0jb.jpg';" /></a>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"  id="dataModelBtnClose" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="updateChanges()">{{isAdd? "Add" : "Update"}} changes</button>
            </div>
        </div>
    </div>
</div>

<notifier-container></notifier-container>

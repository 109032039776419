<div class="row">
    <div class="col-lg-10">
        <div class="row">
            <h2>{{empName}} ({{calculatePricing()}} Lkr)</h2>
        </div>

        <mat-accordion class="example-headers-align">
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <i class="fa fa-box"></i> &nbsp; Item Count Wise
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="col-12 m-t-10 m-b-10">
                    <div class="card">
                        <div class="card-body">
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-hover table-bordered table-striped"  style="text-align: center;">
                                        <thead>
                                            <tr>
                                                <th scope="col">Item Name</th>
                                                <th scope="col">Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let indexElement of getItemList()">
                                                <td>{{indexElement.name}}</td>
                                                <td>{{indexElement.qty}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Tools</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let indexElement of getRows()" [style.background-color]="isAddition(indexElement.type) ? '#F9FFF3' : '#FFF3F3'">
                                    <td>{{isAddition(indexElement.type) ? "+" : "-"}}</td>
                                    <td>{{indexElement.date}}</td>
                                    <td>{{indexElement.type}}</td>
                                    <td>{{indexElement.description}}</td>
                                    <td>{{isAddition(indexElement.type) ?  indexElement.amount : "( - " + indexElement.amount + " )"}}</td>
                                    <td>
                                        <a (click)="updateEmployeeBlock(indexElement._id, indexElement.description, indexElement.amount, indexElement.type)" class="pointer"><i class="fa fa-edit" ></i></a>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <a (click)="deletePayment(indexElement)" class="pointer"><i class="fa fa-trash" style="color:red"></i></a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
    </div>

    <div class="col-lg-2">
        <label>Start Date</label>
        <input [(ngModel)]="startDate" class="form-control" ngModel type="date" value="{{startDate}}" (change)="dateUpdate()"/><br/>
        <label>End Date</label>
        <input [(ngModel)]="endDate" class="form-control" ngModel type="date" value="{{endDate}}" (change)="dateUpdate()"/>
        <br/><br/>
        <button (click)="openItemModel()" class="btn btn-rounded btn-block btn-primary halfButton" type="button">
            Add Payment
        </button>
<!--        <button (click)="addBasicSalary()" class="btn btn-rounded btn-block btn-outline-primary halfButton" type="button">-->
<!--            Add Basic Salary-->
<!--        </button>-->
        <button (click)="openInvoice()" class="btn btn-rounded btn-block btn-warning halfButton" type="button">
            Generate PaySheet
        </button>
        <button (click)="openInvoiceWithOutBasic()" class="btn btn-rounded btn-block btn-outline-secondary halfButton" type="button">
            PaySheet Without Basic
        </button>
        <button (click)="previousPaySheets()" class="btn btn-rounded btn-block btn-outline-primary halfButton" type="button">
            Previous PaySheets
        </button>
        <button (click)="exportAsExcel()" class="btn btn-rounded btn-block btn-secondary halfButton" type="button">
            Download As Excel
        </button>
    </div>

</div>


<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#dataModel" id="dataModelBtn" style="display: none">
</button>

<!-- Modal -->
<div class="modal fade" id="dataModel" tabindex="-1" role="dialog" aria-labelledby="dataModel" aria-hidden="true" >
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="exampleModalLabel">{{isAdd? "Add" : "Update"}} Payment Details</h1>
                <button id="modelClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="col-12 m-t-10 m-b-10"   *ngIf="!isAdd">
                    <label class="form-control-label">Id</label>
                    <input [(ngModel)]="paymentId" class="form-control" ngModel type="text" value="{{paymentId}}" disabled/>
                </div>

                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Date</label>
                    <input [(ngModel)]="date" class="form-control" ngModel type="date" value="{{date}}"/>
                </div>

                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Type</label>
                    <select [(ngModel)]="type" class="form-control" ngModel value="{{type}}" (change)="setEpfAmount()">
                        <option value="Basic-Salary">Basic Salary</option>
                        <option value="Sunday-Allowance">Sunday Allowance</option>
                        <option value="Weekend-Allowance">Weekend Allowance</option>
                        <option value="NightDuty-Allowance">Night Duty Allowance</option>
                        <option value="PoyaDay-Allowance">Poya Day Allowance</option>
                        <option value="Transport-Allowance">Transport Allowance</option>
                        <option value="Budgetary-Relief-Allowance">Budgetary Relief Allowance</option>
                        <option value="living-cost-allowance">Living Cost Allowance</option>
                        <option value="Over-Time">Over Time</option>
                        <option value="Sales-Commission">Sales Commission</option>
                        <option value="Performance-Incentive">Performance Incentive</option>
                        <option value="E-P-F-Employees-Contribution">E.P.F. Employee's Contribution</option>
                        <option value="Salary-Advance">Salary Advances</option>
                        <option value="Insurance">Insurance</option>
                        <option value="Loans">Loans</option>
                        <option value="Lunch">Lunch</option>
                    </select>
                </div>

                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Description</label>
                    <input [(ngModel)]="description" class="form-control" ngModel type="text" value="{{description}}"/>
                </div>


                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Amount</label>
                    <input [(ngModel)]="amount" class="form-control" ngModel type="number" value="{{amount}}"  (change)="updateManualPriceChange()"/>
                </div>


                <div class="col-12 m-t-10 m-b-10">
                    <br/>
                    <p class="lineBeside" style="text-align: center">Or</p>
                </div>

                <div class="row">

                    <div class="col-6 m-t-10 m-b-10">
                        <label class="form-control-label">Item Type</label>
                        <select [(ngModel)]="itemTypeId" class="form-control" ngModel value="{{itemTypeId}}" (change)="updatePriceOnItemChange()">
                            <option value="1">1 LKR</option>
                            <option  *ngFor="let indexElement of itemList" [value]="indexElement._id">
                                {{indexElement.name}}
                            </option>
                        </select>
                    </div>

                    <div class="col-6 m-t-10 m-b-10">
                        <label class="form-control-label">Count</label>
                        <input [(ngModel)]="itemCount" class="form-control" ngModel type="number" value="{{itemCount}}"  (keyup)="updatePriceOnItemChange()"/>
                    </div>
                </div>

                <div class="col-lg-12" *ngIf="isAdd">
                    <button (click)="addPayment()" class="btn btn-rounded btn-block btn-primary halfButton" type="button">
                        Add Payment
                    </button>
                </div>

                <div class="col-lg-12"  *ngIf="!isAdd">
                    <button (click)="updateEmployeeDetails()" class="btn btn-rounded btn-block btn-primary halfButton"
                            type="button">
                        Update Payment
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>


<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#dataTableView" id="previousOrdersModel" style="display: none">
</button>

<!-- Modal -->
<div class="modal fade" id="dataTableView" tabindex="-1" role="dialog" aria-labelledby="dataModel" aria-hidden="true" >
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="previousOrders">Previous PaySheets</h1>
                <button id="previousOrdersClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <table class="table table-hover table-bordered table-striped" style="text-align: center;">
                    <thead>
                    <tr>
                        <th scope="col">Created Date</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Open</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let indexElement of sheetList">
                        <td>{{formatDate(indexElement.createdAt)}}</td>
                        <td>{{indexElement.amount}}</td>
                        <td><a (click)="openPaySheet(indexElement.url)"><button  class="btn btn-primary" >View</button></a></td>
                        <td><a (click)="deleteSheet(indexElement)" class="pointer"><i class="fa fa-trash" style="color:red"></i></a></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<notifier-container></notifier-container>

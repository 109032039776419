import { Component, OnInit } from '@angular/core';
import {ServiceService} from '../../service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {WebappService} from '../WebApp/webapp.service';

@Component({
  selector: 'app-invoice-web',
  templateUrl: './invoice-web.component.html',
  styleUrls: ['./invoice-web.component.css']
})
export class InvoiceWebComponent implements OnInit {
  orderId: any;
  currentDateAndTime = new Date();
  todayDate: any;
  orderData: any;

  constructor(private serviceClass: WebappService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.orderId = params['orderId'];
    });
  }

  ngOnInit() {
    this.todayDate = this.currentDateAndTime.getDate() + "/" + (this.currentDateAndTime.getMonth() + 1) + "/" + this.currentDateAndTime.getFullYear();

    this.serviceClass.getSpecificOrder(this.orderId).subscribe(
        data => {
          this.orderData = data.data;
        }
    )
  }
}

<div *ngIf="serviceClass.isPayrollAllowed()">
    <div class="row">

        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-2">
                </div>
                <div class="col-lg-2">
                </div>
                <div class="col-lg-2">
                </div>
                <div class="col-lg-2">
                </div>
                <div class="col-lg-2">
                    <button (click)="openAddItemModel()" class="btn btn-rounded btn-block btn-primary halfButton" type="button">
                        Add Items
                    </button>
                </div>
                <div class="col-lg-2">
                    <button (click)="exportAsExcel()" class="btn btn-rounded btn-block btn-secondary halfButton" type="button">
                        Download As Excel
                    </button>
                </div>
            </div>
            <br/>
        </div>
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
                                <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Price</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        (click)="updateItemBlock(indexElement._id, indexElement.name, indexElement.price)"
                                        *ngFor="let indexElement of rows"
                                >
                                    <td>{{indexElement.name}}</td>
                                    <td>{{indexElement.price}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#dataModel" id="dataModelBtn" style="display: none">
    </button>

    <!-- Modal -->
    <div class="modal fade" id="dataModel" tabindex="-1" role="dialog" aria-labelledby="dataModel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title" id="exampleModalLabel">{{isAdd? "Add" : "Update"}} Item Details</h1>
                    <button id="modelClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="col-12 m-t-10 m-b-10" *ngIf="!isAdd">
                        <label class="form-control-label">Id</label>
                        <input [(ngModel)]="itemId" class="form-control" ngModel type="text" value="{{itemId}}" disabled/>
                    </div>

                    <div class="col-12 m-t-10 m-b-10">
                        <label class="form-control-label">Name</label>
                        <input [(ngModel)]="itemName" class="form-control" ngModel type="text" value="{{itemName}}"/>
                    </div>

                    <div class="col-12 m-t-10 m-b-10">
                        <label class="form-control-label">Price</label>
                        <input [(ngModel)]="itemPrice" class="form-control" ngModel type="number" value="{{itemPrice}}"/>
                    </div>

                    <div class="col-lg-12"  *ngIf="isAdd">
                        <button (click)="addItems()" class="btn btn-rounded btn-block btn-primary halfButton" type="button">
                            Add Items
                        </button>
                    </div>


                    <div class="col-lg-12"  *ngIf="!isAdd">
                        <button (click)="updateEmployeeDetails()" class="btn btn-rounded btn-block btn-primary halfButton"
                                type="button">
                            Update Item
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <notifier-container></notifier-container>
</div>
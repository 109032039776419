import {Component, OnInit} from '@angular/core';
import {NotifierService} from "angular-notifier";
import {ServiceService} from "../../../service.service";
import {ActivatedRoute, Router} from "@angular/router";
import * as XLSX from 'xlsx';
import {PayrollService} from '../payroll.service';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.css']
})
export class ManageUserComponent implements OnInit {
  keyword = 'name';
  rows = [];

  confirmOrderAccess: any;
  deliveredOrderAccess: any;
  canceledOrderAccess: any;
  noteId: number;
  private notifier: NotifierService;

  isAdd: any=true;
  pEmpId: any="none";
  pEmpName: any="none";
  pEmpType: any="daily";
  basicSalaryPayment: any= 0;
  providentFundNo: any;

  constructor(public serviceClass: PayrollService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.updatePayrollDetails();
  }

  updatePayrollDetails(): void {
    this.serviceClass.payrollGetAllUsers().subscribe(
        data => {
          this.rows = data;
          document.getElementById("modelClose").click();
        }
    );
  }


  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }


  openItemModel(){
    this.isAdd = true;
    this.pEmpName = "";
    this.providentFundNo = "";
    this.pEmpType = "monthly";
    document.getElementById("dataModelBtn").click();
  }

  addEmployee() {
    if (this.pEmpName != "none") {
      this.serviceClass.payrollUserAdd({name: this.pEmpName , type: this.pEmpType, monthlyPayment: this.basicSalaryPayment, providentFundNo: this.providentFundNo}).subscribe(
          data => {
            this.updatePayrollDetails();
            this.pEmpName = "";
            this.providentFundNo = "";
          }
      );
    } else {
      this.showNotification("error", "Please select user details!");
    }
  }


  updateEmployeeBlock(id: any, name: any, type: any, monthlyPayment: any, providentFundNo: any) {
    this.isAdd = false;
    this.pEmpId = id;
    this.pEmpName = name;
    this.pEmpType = type;
    this.basicSalaryPayment = monthlyPayment;
    this.providentFundNo = providentFundNo;
    document.getElementById("dataModelBtn").click();
  }

  updateEmployeeDetails() {
    if (this.pEmpId != "none") {
      this.serviceClass.payrollUserUpdate({id: this.pEmpId, name: this.pEmpName, type: this.pEmpType, monthlyPayment:  this.basicSalaryPayment, providentFundNo: this.providentFundNo}).subscribe(
          data => {
            if (data.status) {
              this.updatePayrollDetails();
              this.showNotification("success", "Item Updated Successfully!");
            } else {
              this.showNotification("error", "There is a error. Please check and try again!");
            }
          }
      );
    } else {
      this.showNotification("error", "Please select user details!");
    }
  }

  setAccordionId(index: number) {
    if(index === 0){
      this.pEmpName = "";
      this.basicSalaryPayment = 0;
    }
    this.noteId = index;
  }

  exportAsExcel(): void {
    let currentTimeAndDate = new Date();
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.formatDate(currentTimeAndDate) + " - All Items.xlsx");
  }


  formatDate(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

  goToPage(indexElement) {
    const windowHash = window.location.hash;
    const urlArray = windowHash.split("/");
    let params = new URLSearchParams(indexElement).toString();
    window.open('/#/' + urlArray[1] + '/payroll/user/profile?'+params, "", "width=1500,height=3000");
  }


  routeToSingleOrderPage(indexElement) {
    const windowHash = window.location.hash;
    const urlArray = windowHash.split("/");
    this.router.navigate(['/' + urlArray[1] + '/payroll/user/profile'], {queryParams: indexElement});
  }

}

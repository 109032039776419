<div *ngIf="serviceClass.isPayrollAllowed()">
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-2">
                </div>
                <div class="col-lg-2">
                </div>
                <div class="col-lg-2">
                </div>
                <div class="col-lg-2">
                </div>
                <div class="col-lg-2">
                    <button (click)="openItemModel()" class="btn btn-rounded btn-block btn-primary halfButton" type="button">
                        Add Employee
                    </button>
                </div>
                <div class="col-lg-2">
                    <button (click)="exportAsExcel()" class="btn btn-rounded btn-block btn-secondary halfButton" type="button">
                        Download As Excel
                    </button>
                </div>
            </div>
            <br/>
        </div>
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
                                <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Monthly Payment</th>
                                    <th scope="col">Provident Fund No</th>
                                    <th scope="col">Tools</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        *ngFor="let indexElement of rows"
                                >
                                    <td>{{indexElement.name}} - <a (click)="goToPage(indexElement)" class="pointer"><i class="fa fa-eye" ></i></a></td>
                                    <td>{{indexElement.type}}</td>
                                    <td>{{indexElement.monthlyPayment}}</td>
                                    <td>{{indexElement.providentFundNo}}</td>
                                    <td>
                                        <a (click)="updateEmployeeBlock(indexElement._id, indexElement.name, indexElement.type, indexElement.monthlyPayment, indexElement.providentFundNo)" class="pointer"><i class="fa fa-edit" ></i></a>&nbsp;&nbsp;
                                        <a (click)="goToPage(indexElement)" class="pointer"><i class="fa fa-eye" ></i></a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#dataModel" id="dataModelBtn" style="display: none">
    </button>

    <!-- Modal -->
    <div class="modal fade" id="dataModel" tabindex="-1" role="dialog" aria-labelledby="dataModel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title" id="exampleModalLabel">{{isAdd? "Add" : "Update"}} Employee Details</h1>
                    <button id="modelClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="col-12 m-t-10 m-b-10"   *ngIf="!isAdd">
                        <label class="form-control-label">Id</label>
                        <input [(ngModel)]="pEmpId" class="form-control" ngModel type="text" value="{{pEmpId}}" disabled/>
                    </div>

                    <div class="col-12 m-t-10 m-b-10">
                        <label class="form-control-label">Name</label>
                        <input [(ngModel)]="pEmpName" class="form-control" ngModel type="text" value="{{pEmpName}}"/>
                    </div>

                    <div class="col-12 m-t-10 m-b-10">
                        <label class="form-control-label">Type</label>
                        <select [(ngModel)]="pEmpType" class="form-control" ngModel value="{{pEmpType}}">
                            <option value="daily">Daily Payment</option>
                            <option value="monthly">Monthly Payment</option>
                            <option value="orderWise">Order Wise Payments</option>
                        </select>
                    </div>

                    <div class="col-12 m-t-10 m-b-10">
                        <label class="form-control-label">Basic Salary</label>
                        <input [(ngModel)]="basicSalaryPayment" class="form-control" ngModel type="number" value="{{basicSalaryPayment}}"/>
                    </div>

                    <div class="col-12 m-t-10 m-b-10">
                        <label class="form-control-label">Provident Fund No</label>
                        <input [(ngModel)]="providentFundNo" class="form-control" ngModel type="text" value="{{providentFundNo}}"/>
                    </div>

                    <div class="col-lg-12" *ngIf="isAdd">
                        <button (click)="addEmployee()" class="btn btn-rounded btn-block btn-primary halfButton" type="button">
                            Add Employee
                        </button>
                    </div>

                    <div class="col-lg-12"  *ngIf="!isAdd">
                        <button (click)="updateEmployeeDetails()" class="btn btn-rounded btn-block btn-primary halfButton"
                                type="button">
                            Update Employee
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <notifier-container></notifier-container>

</div>
<div *ngIf="serviceClass.isPayrollAllowed()">
    <div class="row">
        <div class="col-lg-10">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col" *ngFor="let itemElement of allItemList">
                                            {{itemElement.name === "1" ? "Number Addition" : itemElement.name}}
                                        </th>
                                        <td>Total Price</td>
                                        <td>Loan</td>
                                        <td>Deduction</td>
                                        <td>Lunch</td>
                                        <td>Net</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let userObj of rows" >
                                        <td>{{userObj.name}}</td>
                                        <td *ngFor="let itemElement of allItemList">
                                            {{getItemCount(itemElement._id, userObj._id)}}
                                        </td>
                                        <td>{{getItemTotalPricing(userObj._id)}}</td>
                                        <td>{{calculate(userObj._id, 'Loans')}}</td>
                                        <td>{{calculate(userObj._id, 'Salary-Advance') + calculate(userObj._id, 'Insurance') + calculate(userObj._id, 'E-P-F-Employees-Contribution')}}</td>
                                        <td>{{calculate(userObj._id, 'Lunch')}}</td>
                                        <td>{{ getItemTotalPricing(userObj._id) - (calculate(userObj._id, 'Loans') + calculate(userObj._id, 'Lunch') + calculate(userObj._id, 'Salary-Advance') + calculate(userObj._id, 'Insurance') + calculate(userObj._id, 'E-P-F-Employees-Contribution'))}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-2">
            <label>Start Date</label>
            <input [(ngModel)]="startDate" class="form-control" ngModel type="date" value="{{startDate}}" (change)="dateUpdate()" /><br/>
            <label>End Date</label>
            <input [(ngModel)]="endDate" class="form-control" ngModel type="date" value="{{endDate}}" (change)="dateUpdate()"/>
            <button (click)="exportAsExcel()" class="btn btn-rounded btn-block btn-secondary halfButton" type="button">
                Download As Excel
            </button>
        </div>

    </div>


    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#dataModel" id="dataModelBtn" style="display: none">
    </button>

    <!-- Modal -->
    <div class="modal fade" id="dataModel" tabindex="-1" role="dialog" aria-labelledby="dataModel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title" id="exampleModalLabel">{{isAdd? "Add" : "Update"}} Employee Details</h1>
                    <button id="modelClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="col-12 m-t-10 m-b-10"   *ngIf="!isAdd">
                        <label class="form-control-label">Id</label>
                        <input [(ngModel)]="pEmpId" class="form-control" ngModel type="text" value="{{pEmpId}}" disabled/>
                    </div>

                    <div class="col-12 m-t-10 m-b-10">
                        <label class="form-control-label">Name</label>
                        <input [(ngModel)]="pEmpName" class="form-control" ngModel type="text" value="{{pEmpName}}"/>
                    </div>

                    <div class="col-12 m-t-10 m-b-10">
                        <label class="form-control-label">Type</label>
                        <select [(ngModel)]="pEmpType" class="form-control" ngModel value="{{pEmpType}}">
                            <option value="daily">Daily Payment</option>
                            <option value="monthly">Monthly Payment</option>
                            <option value="orderWise">Order Wise Payments</option>
                        </select>
                    </div>

                    <div class="col-12 m-t-10 m-b-10">
                        <label class="form-control-label">Basic Salary</label>
                        <input [(ngModel)]="basicSalaryPayment" class="form-control" ngModel type="number" value="{{basicSalaryPayment}}"/>
                    </div>

                    <div class="col-12 m-t-10 m-b-10">
                        <label class="form-control-label">Provident Fund No</label>
                        <input [(ngModel)]="providentFundNo" class="form-control" ngModel type="text" value="{{providentFundNo}}"/>
                    </div>

                    <div class="col-lg-12" *ngIf="isAdd">
                        <button (click)="addEmployee()" class="btn btn-rounded btn-block btn-primary halfButton" type="button">
                            Add Employee
                        </button>
                    </div>

                    <div class="col-lg-12"  *ngIf="!isAdd">
                        <button (click)="updateEmployeeDetails()" class="btn btn-rounded btn-block btn-primary halfButton"
                                type="button">
                            Update Employee
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <notifier-container></notifier-container>
</div>

import {Component, OnInit} from '@angular/core';
import {NotifierService} from "angular-notifier";
import {ServiceService} from "../../../service.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import * as XLSX from "xlsx";


// This is for the range date picker
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
    one &&
    two &&
    two.year === one.year &&
    two.month === one.month &&
    two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
    !one || !two
        ? false
        : one.year === two.year
            ? one.month === two.month
                ? one.day === two.day
                    ? false
                    : one.day < two.day
                : one.month < two.month
            : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
    !one || !two
        ? false
        : one.year === two.year
            ? one.month === two.month
                ? one.day === two.day
                    ? false
                    : one.day > two.day
                : one.month > two.month
            : one.year > two.year;

// End  range date picker


@Component({
  selector: 'app-category-wise-delivery',
  templateUrl: './category-wise-delivery.component.html',
  styleUrls: ['./category-wise-delivery.component.css']
})
export class CategoryWiseDeliveryComponent implements OnInit {

  // This is for the range date picker
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct = null;
  toDate: NgbDateStruct = null;
  keyword = 'name';
  rows = [];
  allUsers: any[];
  phoneDetailsJsonArray: any[];
  currentSelectedEmployeeName: any = "All";
  currentSelectedEmployeeEmail: any = "All";
  totalOrders: any = 0;
  confirmedOrders: any = 0;
  pendingOrdersCount: any = 0;
  dispatchedOrdersCount: any = 0;
  confirmedOrdersCount: any = 0;
  deliveredOrdersCount: any = 0;
  returnedOrdersCount: any = 0;
  warrantyOrdersCount: any = 0;
  canceledOrdersCount: any;
  categoryBasedPayment: any;
  paymentList: any;
  private notifier: NotifierService;

  constructor(private serviceClass: ServiceService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.serviceClass.getAllEmployees().subscribe(
        data => {
          let employeeData = [{email: "All", name: "All"}];

          for (let item of data) {
            employeeData.push({name: item["name"], email: item["email"]});
          }
          this.allUsers = employeeData;
        }
    );

    this.serviceClass.getAllItems().subscribe(
        itemList => {
          this.phoneDetailsJsonArray = itemList;
          // this.serviceClass.getAllOrders(25).subscribe(
          //     orderList => {
          //       let datas = orderList;
          //       this.rows = [...datas];
          //       this.updateOrderAndConfirmedCounts();
          //     }
          // );
        }
    );
  }

  roundTo(n, digits) {
    if (digits === undefined) {
      digits = 0;
    }

    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    var test = (Math.round(n) / multiplicator);
    return +(test.toFixed(digits));
  }

  exportAsExcel(): void {
    let currentTimeAndDate = new Date();
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.formatDate(currentTimeAndDate) + " - Order Accuracy Report.xlsx");
  }

  formatTime(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " " + newDate.getHours() + ":" + newDate.getMinutes();
  }

  formatDate(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

  getFormattedDateOfDelivery(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.toISOString().split('T')[0];
  }

  getTheFirstCharacter(empName: any) {
    return empName.substr(0, 1).toUpperCase();
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }


  resetThePage() {
    window.location.reload();
  }

  onDateChange(date: NgbDateStruct) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered = date =>
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      after(date, this.fromDate) &&
      // tslint:disable-next-line:semicolon
      before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);

  selectedEmployee(jsonValue: any) {
    this.currentSelectedEmployeeName = jsonValue["name"];
    this.currentSelectedEmployeeEmail = jsonValue["email"];
  }

  dateFormatter(dateString: any) {
    let newDate = new Date(dateString);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

  timeFormatter(dateString: any) {
    let newDate = new Date(dateString);
    return newDate.getHours() + ":" + newDate.getMinutes() + ":" + newDate.getSeconds();
  }

  routeToSingleOrderPage(orderNumber: any) {
    this.router.navigate(['/admin/order_details'], {queryParams: {orderId: orderNumber}});
  }

  getJsonObjectFromItemDetails(id: any) {
    for (let item of this.phoneDetailsJsonArray) {
      if (item["deviceId"] == id) {
        return item;
      }
    }
  }

  getItemName(id: any) {
    let deviceArray = this.getJsonObjectFromItemDetails(id);
    if (deviceArray != undefined) {
      return deviceArray["name"];
    } else {
      return "No Item";
    }
  }

  updateOrderAndConfirmedCounts() {
    this.pendingOrdersCount = 0;
    this.dispatchedOrdersCount = 0;
    this.confirmedOrdersCount = 0;
    this.deliveredOrdersCount = 0;
    this.canceledOrdersCount = 0;
    this.returnedOrdersCount = 0;
    this.warrantyOrdersCount = 0;

    this.totalOrders = this.rows.length;
    for (let item of this.rows) {
      if (item["deliveryStatus"] == 'pending') {
        this.pendingOrdersCount += 1;
      } else if (item["deliveryStatus"] == 'confirmedWithCOD' || item["deliveryStatus"] == 'confirmed') {
        this.confirmedOrdersCount += 1;
      } else if (item["deliveryStatus"] == 'dispatched') {
        this.dispatchedOrdersCount += 1;
      } else if (item["deliveryStatus"] == 'delivered') {
        this.deliveredOrdersCount += 1;
      } else if (item["deliveryStatus"] == 'canceled') {
        this.canceledOrdersCount += 1;
      } else if (item["deliveryStatus"] == 'return') {
        this.returnedOrdersCount += 1;
      } else if (item["deliveryStatus"] == 'warrenty') {
        this.warrantyOrdersCount += 1;
      }
    }
  }

  getLabelType(deliveryStatus: any) {
    if (deliveryStatus == "confirmed") {
      return "label-success confirmed";
    } else {
      return "label-danger notConfirmed";
    }
  }

  getCustomLabel(deliveryStatus: any) {
    if (deliveryStatus == "confirmed") {
      return "Confirmed";
    } else {
      return deliveryStatus;
    }
  }

  updateTable() {
    this.totalOrders += 0;
    this.confirmedOrders += 0;
    let today = new Date();

    let tempFromDate = null;
    let tempToDate = null;

    if (this.fromDate != null) {
      let fromDateObj: Date = new Date(this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day);

      // fromDateObj.setDate(fromDateObj.getDate() - 1);
      tempFromDate = fromDateObj.getFullYear() + "-" + (fromDateObj.getMonth() + 1) + "-" + fromDateObj.getDate();

      if (this.toDate == null) {
        tempToDate = this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day;
      } else {
        tempToDate = this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day;
      }
    }

    this.serviceClass.getDispatchBasedOnDeliveryType("All", tempFromDate, tempToDate).subscribe(
        data => {
          this.rows = [...data];

          this.rows = this.rows.map(order=> {
            return {...order}
          });

          this.paymentList = this.rows.map(item=> {
            return item.paymentStatus
          }).filter(value=> value).filter((value, index, self) => self.indexOf(value) === index)

          const categoryList = this.phoneDetailsJsonArray.map(item=> {
            return item.category
          }).filter(value=> value.length > 0).filter((value, index, self) => self.indexOf(value) === index)

          this.categoryBasedPayment = categoryList.map((category) => {
            const categoryPayment = { category: category };
            this.paymentList.forEach((paymentType) => {
              const count = this.rows.filter((item) => item.paymentStatus === paymentType && item.category === category).length;
              categoryPayment[paymentType] = count;
            });
            return categoryPayment;
          });

          const itemDetails = [];
          this.rows.forEach((item) => {
            [1,2,3,4,5].map(value=> {
              this.categoryBasedPayment = this.categoryBasedPayment.map(categoryElement => {
                // console.log(this.getItemCategory(item[`device${value}`]));
                if(categoryElement.category === this.getItemCategory(item[`device${value}`]) && this.paymentList.includes(item.paymentStatus)) {

                  const tempItem = this.getItem(item[`device${value}`])
                  itemDetails.push(tempItem);



                  return {...categoryElement, [item.paymentStatus]: categoryElement[item.paymentStatus] + (item[`numDevice${value}`] * this.getItemPricing(item[`device${value}`]))}
                } else {
                  return categoryElement;
                }
              })
            })
          })
          const jsonOutput = JSON.stringify(itemDetails, null, 2);
          console.log(jsonOutput);
          this.updateOrderAndConfirmedCounts();
        }
    );
  }

  getItemCategory(id) {
    const temp = this.phoneDetailsJsonArray.find(item => item.deviceId.toString() === id);
    if (temp) {
      const category = temp.category;
      if (category !== undefined && category !== null) {
        return category;
      }
    }
    return '';
  }
  getItem(id) {
    const temp = this.phoneDetailsJsonArray.find(item => item.deviceId.toString() === id);
    if (temp) {
      const category = temp;
      if (category !== undefined && category !== null) {
        return category;
      }
    }
    return '';
  }

  getItemPricing(id) {
    const temp = this.phoneDetailsJsonArray.find(item => item.deviceId.toString() === id);
    if (temp) {
      const soldPrice = temp.soldPrice;
      if (soldPrice !== undefined && soldPrice !== null) {
        return soldPrice;
      }
    }
    return '';
  }
}

import { Component, OnInit } from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {WebappService} from '../webapp.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-order-manager',
  templateUrl: './order-manager.component.html',
  styleUrls: ['./order-manager.component.css']
})
export class OrderManagerComponent implements OnInit {
  rows = [];
  isAdd=true;

  tempData = {
    _id: "",
    orderStatus:"",
    items:[]
  };
  initialData = {
    _id: "",
    orderStatus:"",
    items:[]
  }

  private notifier: NotifierService;

  constructor(private serviceClass: WebappService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.updateTableData();
  }

  updateTableData(){
    this.serviceClass.getAllOrders().subscribe(
        data => {
          this.rows = data.data;
        }
    );
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  resetThePage() {
    window.location.reload();
  }

  openWidgetForAdd() {
    this.isAdd = true;
    this.tempData = this.initialData;
    document.getElementById("dataModelBtn").click()
  }

  openWidgetForEditing(data: any) {
    this.isAdd = false;
    this.tempData = data;
    document.getElementById("dataModelBtn").click()
  }

  deleteProduct(data:any) {
    if(confirm("Do you want to delete this item? ("+data.firstName+")")) {
      this.serviceClass.deleteOrders(data).subscribe(
          data => {
            this.updateTableData();
            this.showNotification("success", "Deleted successfully!")
          }
      );
    }
  }

  updateChanges() {
    if(this.isAdd){
      this.serviceClass.addOrders(this.tempData).subscribe(
          data => {
            this.updateTableData();
            document.getElementById("dataModelBtnClose").click()
            this.showNotification("success", "Added successfully!")
          }
      );
    } else {
      this.serviceClass.updateOrders(this.tempData).subscribe(
          data => {
            this.updateTableData();
            document.getElementById("dataModelBtnClose").click()
            this.showNotification("success", "Updated successfully!")
          }
      );
    }
  }

  dateFormatter(dateString: any) {
    let newDate = new Date(dateString);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " "+newDate.getHours() + ":"+newDate.getMinutes();
  }

  printPage(indexElement: any) {
    window.print()
  }

  routeToInvoice(orderNumber: any) {
    this.router.navigate(['/invoice/web/show'], {queryParams: {orderId: orderNumber}});
  }


  getLabelType(deliveryStatus: any) {
    if (deliveryStatus == "pending") {
      return "label-primary pending";
    } else if (deliveryStatus == "confirmed") {
      return "label-info confirmed";
    } else if (deliveryStatus == "dispatched") {
      return "label-warning dispatched";
    } else if (deliveryStatus == "delivered") {
      return "label-success delivered";
    } else if (deliveryStatus == "return") {
      return "label-danger return";
    } else if (deliveryStatus == "canceled") {
      return "label-warning canceled";
    } else if (deliveryStatus == "warrenty") {
      return "label-secondary warrenty";
    }
  }
}

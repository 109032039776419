import { Component, OnInit } from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {ActivatedRoute, Router} from '@angular/router';
import * as XLSX from 'xlsx';
import {PayrollService} from '../payroll.service';

@Component({
  selector: 'app-item-types',
  templateUrl: './item-types.component.html',
  styleUrls: ['./item-types.component.css']
})
export class ItemTypesComponent implements OnInit {
  keyword = 'name';
  rows = [];
  allUsers: any[];
  phoneDetailsJsonArray: any[];
  currentSelectedEmployeeName: any = "All";
  currentSelectedEmployeeEmail: any = "All";
  totalOrders: any = 0;
  confirmedOrders: any = 0;

  employeeEmail: any = "none";
  empPhone: any;

  confirmOrderAccess: any;
  deliveredOrderAccess: any;
  canceledOrderAccess: any;
  noteId: number;
  private notifier: NotifierService;

  isAdd: any=true;
  itemId: any="none";
  itemName: any="none";
  itemPrice: any="daily";

  constructor(public serviceClass: PayrollService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.updatePayrollDetails();
  }

  updatePayrollDetails(): void {
    this.serviceClass.payrollGetAllItems().subscribe(
        data => {
          this.rows = data;
        }
    );
  }


  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  openAddItemModel(){
    this.isAdd = true;
    this.itemId = "";
    this.itemName = "";
    this.itemPrice = 0;
    document.getElementById("dataModelBtn").click();
  }


  addItems() {
    if (this.itemName != "none") {
      this.serviceClass.payrollItemsAdd({name: this.itemName , price: this.itemPrice}).subscribe(
          data => {
            this.updatePayrollDetails();
            this.itemName = "";
            this.itemPrice = 0;
            document.getElementById("modelClose").click();
          }
      );
    } else {
      this.showNotification("error", "Please select user details!");
    }
  }


  updateItemBlock(id: any, name: any, price: any) {
    this.isAdd = false;
    this.itemId = id;
    this.itemName = name;
    this.itemPrice = price;
    document.getElementById("dataModelBtn").click();
  }

  updateEmployeeDetails() {
    if (this.itemId != "none") {
      this.serviceClass.payrollItemsUpdate({id: this.itemId, name: this.itemName, price: this.itemPrice}).subscribe(
          data => {
            if (data.status) {
              this.updatePayrollDetails();
              this.showNotification("success", "Item Updated Successfully!");
              document.getElementById("modelClose").click();
            } else {
              this.showNotification("error", "There is a error. Please check and try again!");
            }
          }
      );
    } else {
      this.showNotification("error", "Please select user details!");
    }
  }

  setAccordionId(index: number) {
    if(index === 0){
      this.itemName = "";
    }
    this.noteId = index;
  }

  exportAsExcel(): void {
    let currentTimeAndDate = new Date();
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.formatDate(currentTimeAndDate) + " - All Items.xlsx");
  }


  formatDate(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

}

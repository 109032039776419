import {RouteInfo} from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

  {
    path: '/admin/main',
    title: 'All Orders',
    icon: 'fas fa-address-book',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/add_order',
    title: 'Add Orders',
    icon: 'fas fa-shopping-basket',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/order/warranty-manager',
    title: 'Warranty Manager',
    icon: 'fas fa-shield-alt',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/reports/pronto-uploadSheet',
    title: 'Pronto Upload Sheet',
    icon: 'fas fa-upload',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/dispatch/address-print',
    title: 'Address Print',
    icon: 'fas fa-print',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/reports/all-oderDetails',
    title: 'Order Details',
    icon: 'fas fa-info-circle',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/order/dispatch/now',
    title: 'Dispatch Orders',
    icon: 'fas fa-ship',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/order/return/now',
    title: 'Return Orders',
    icon: 'fas fa-undo',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Payroll',
    icon: 'far fa-credit-card',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/admin/payroll/users',
        title: 'Manage Users',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/payroll/user/paysheet-report',
        title: 'Paysheet Report',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/payroll/items',
        title: 'Manage Items',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Reports',
    icon: 'far fa-chart-bar',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/admin/reports/order_creation',
        title: 'Order Creation',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/reports/order_confirmation',
        title: 'Order Confirmation',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/reports/order_pending',
        title: 'Order Pending',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/reports/order_delivered',
        title: 'Order Delivered',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/reports/order_returned',
        title: 'Create to Returned',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/reports/order_canceled',
        title: 'Order Canceled',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/reports/delivery-baseOn-date',
        title: 'Day Sale',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/reports/category-wise-delivery',
        title: 'Cetegory Wise Delivery',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/reports/accuracy-rate',
        title: 'Success Rate',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/reports/item-wise',
        title: 'Item Wise',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/reports/dispatch-to-delivered-date-count',
        title: 'Dispatch - Delivered',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/reports/dispatch-to-return-date-count',
        title: 'Dispatch - Returned',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/reports/current-dispatched-orders',
        title: 'Currently Dispatched Orders',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/reports/dispatch-with-deliveryType',
        title: 'Dispatch With DeliveryType',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/return/based-on-returned-date',
        title: 'Return Date Based Report',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Checking Tools',
    icon: 'fas fa-wrench',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/admin/order/dispatch-tracker',
        title: 'Dispatch Tracker',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/report/check-return-orders',
        title: 'Check Return',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/checker/pronto-parcel-accept',
        title: 'Acceptance Checker',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/checker/pronto-cash',
        title: 'Pronto Price Checker',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/order/bulk/delivered',
        title: 'Set Bulk Delivered',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Web App',
    icon: 'mdi mdi-web',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/admin/webapp/orders',
        title: 'Manage Orders',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/webapp/products',
        title: 'Manage Products',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/webapp/categories',
        title: 'Manage Categories',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/webapp/brands',
        title: 'Manage Brands',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'System Management',
    icon: 'fas fa-cogs',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/admin/cod/update',
        title: 'Update COD',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/admin/manage/item',
        title: 'Item Management',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },

      {
        path: '/admin/manage/employee',
        title: 'Employee Management',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  }

];

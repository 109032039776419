<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="col-12">
                    <div class="table-responsive">
                        <table class="table table-hover table-bordered table-striped" id="excel-table" style="text-align: center;">
                            <thead>
                            <tr>
                                <th scope="col">Tools</th>
                                <th scope="col">Date</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone Number</th>
                                <th scope="col">Address</th>
                                <th scope="col">City</th>
                                <th scope="col">Zip Code</th>
                                <th scope="col">Total</th>
                                <th scope="col">Note</th>
                                <th scope="col">Delivery Cost</th>
                                <th scope="col">Order Status</th>
                                <th scope="col">Payment Option</th>
                                <th scope="col">Payment Status</th>
                                <th scope="col">Web Payment Status</th>
                                <th scope="col">Payment Amount</th>
                                <th scope="col">Payment ID</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr  *ngFor="let indexElement of rows">
                                    <td>
                                        <div class="row">
                                            <div class="col-4">
                                                <a (click)="routeToInvoice(indexElement._id)" class="pointer"><i class="fa fa-print" ></i></a>
                                            </div>
                                            <div class="col-4">
                                                <a (click)="openWidgetForEditing(indexElement)" class="pointer"><i class="fa fa-edit" ></i></a>
                                            </div>
                                            <div class="col-4">
                                                <a (click)="deleteProduct(indexElement)" class="pointer"><i class="fa fa-trash" style="color: red"></i></a>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{dateFormatter(indexElement.dateTime)}}</td>
                                    <td>{{indexElement.firstName}} {{indexElement.lastName}}</td>
                                    <td>{{indexElement.email}}</td>
                                    <td>{{indexElement.phoneNumber}}</td>
                                    <td>{{indexElement.address}}</td>
                                    <td>{{indexElement.city}}</td>
                                    <td>{{indexElement.zipCode}}</td>
                                    <td>{{indexElement.total}}</td>
                                    <td>{{indexElement.note}}</td>
                                    <td>{{indexElement.deliveryCost}}</td>
                                    <td><span class="label {{getLabelType(indexElement.orderStatus)}}">{{indexElement.orderStatus}}</span></td>
                                    <td>{{indexElement.paymentOption}}</td>
                                    <td>{{indexElement.paymentStatus}}</td>
                                    <td>{{indexElement.webPaymentStatus}}</td>
                                    <td>{{indexElement.paidAmount}}LKR</td>
                                    <td>{{indexElement.paymentId}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#dataModel" id="dataModelBtn" style="display: none">
</button>

<!-- Modal -->
<div class="modal fade" id="dataModel" tabindex="-1" role="dialog" aria-labelledby="dataModel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title" id="exampleModalLabel">Update & View Order Status</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <table class="table table-hover table-bordered table-striped">
                    <tr *ngFor="let items of tempData.items">
                        <th><img src="{{items.image}}" style="width: 100px"></th>
                        <th>{{items.name}}</th>
                        <th>{{items.quantity}} x {{items.price}}.00LKR</th>
                        <th>{{items.itemTotal}}LKR</th>
                    </tr>
                </table>

                <div class="col-12 m-t-10 m-b-10">
                    <label class="form-control-label">Order Status</label>
                    <select [(ngModel)]="tempData.orderStatus" value="{{tempData.orderStatus}}"  class="form-control">
                        <option  value="pending">Pending</option>
                        <option  value="confirmed">Confirmed</option>
                        <option  value="dispatched">Dispatched</option>
                        <option  value="canceled">Canceled</option>
                    </select>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"  id="dataModelBtnClose" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="updateChanges()">{{isAdd? "Add" : "Update"}} changes</button>
            </div>
        </div>
    </div>
</div>

<notifier-container></notifier-container>

import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ResoponseObject} from '../../orders.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebappService {

  // private url = 'http://localhost:8080/';
  private url = 'https://unikwear-backend.herokuapp.com/';
  private readonly IMGUR_UPLOAD_URL = 'https://api.imgur.com/3/image';
  private readonly clientId = '2c1fc41da39c533';

  constructor(private serviceHttp: HttpClient, private http: HttpClient) {}

  getAllProducts(): Observable<any> {
    return this.serviceHttp.get<any>(this.url + 'api/products');
  }

  addProduct(obj: any): Observable<any> {
    return this.serviceHttp.post<any>(this.url + 'api/products', obj);
  }

  updateProduct(obj: any): Observable<any> {
    return this.serviceHttp.put<any>(this.url + 'api/products/'+obj._id, obj);
  }

  deleteProduct(obj: any): Observable<any> {
    return this.serviceHttp.delete<any>(this.url + 'api/products/'+obj._id);
  }

  getAllCategories(): Observable<any> {
    return this.serviceHttp.get<any>(this.url + 'api/categories');
  }

  addCategory(obj: any): Observable<any> {
    return this.serviceHttp.post<any>(this.url + 'api/categories', obj);
  }

  updateCategory(obj: any): Observable<any> {
    return this.serviceHttp.put<any>(this.url + 'api/categories/'+obj._id, obj);
  }

  deleteCategory(obj: any): Observable<any> {
    return this.serviceHttp.delete<any>(this.url + 'api/categories/'+obj._id);
  }

  getAllBrands(): Observable<any> {
    return this.serviceHttp.get<any>(this.url + 'api/brands');
  }

  addBrands(obj: any): Observable<any> {
    return this.serviceHttp.post<any>(this.url + 'api/brands', obj);
  }

  updateBrands(obj: any): Observable<any> {
    return this.serviceHttp.put<any>(this.url + 'api/brands/'+obj._id, obj);
  }

  deleteBrands(obj: any): Observable<any> {
    return this.serviceHttp.delete<any>(this.url + 'api/brands/'+obj._id);
  }

  getAllOrders(): Observable<any> {
    return this.serviceHttp.get<any>(this.url + 'api/order');
  }


  getSpecificOrder(id:any): Observable<any> {
    return this.serviceHttp.get<any>(this.url + 'api/order/'+id);
  }

  addOrders(obj: any): Observable<any> {
    return this.serviceHttp.post<any>(this.url + 'api/order', obj);
  }

  updateOrders(obj: any): Observable<any> {
    return this.serviceHttp.put<any>(this.url + 'api/order/'+obj._id, obj);
  }

  deleteOrders(obj: any): Observable<any> {
    return this.serviceHttp.delete<any>(this.url + 'api/order/'+obj._id);
  }

  upload(b64Image: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Client-ID ${this.clientId}`,
        origin: "**"
      }),
    };
    const formData = new FormData();
    formData.append('image', b64Image);
    return this.http.post(`${this.IMGUR_UPLOAD_URL}`, formData, httpOptions);
  }

  numOfCloudinaryAccounts = 3;
  cloudinaryArray = [{
    cloudName: 'unik-wear',
    maxImageFileSize:1000000,
    clientAllowedFormats:["png","gif", "jpeg"],
    showSkipCropButton:false,
    maxFiles:1,
    uploadPreset: 'ffo5xuja'},
    {
    cloudName: 'unikwearlk-cloud',
    maxImageFileSize:1000000,
    clientAllowedFormats:["png","gif", "jpeg"],
    showSkipCropButton:false,
    maxFiles:1,
    uploadPreset: 'unikwear-cloud-lk'},
    {
    cloudName: 'unikwear-dev',
    maxImageFileSize:1000000,
    clientAllowedFormats:["png","gif", "jpeg"],
    showSkipCropButton:false,
    maxFiles:1,
    uploadPreset: 'unikwear-dev-present'}]
}

import
{Component, OnInit} from '@angular/core';
import {NotifierService} from "angular-notifier";
import {ServiceService} from "../../../service.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import * as XLSX from "xlsx";


// This is for the range date picker
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day < two.day
      : one.month < two.month
    : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day > two.day
      : one.month > two.month
    : one.year > two.year;

// End  range date picker


@Component({
  selector: 'app-item-wise',
  templateUrl: './item-wise.component.html',
  styleUrls: ['./item-wise.component.css']
})
export class ItemWiseComponent implements OnInit {

  // This is for the range date picker
  hoveredDate: NgbDateStruct;

  fromDate: NgbDateStruct = null;
  toDate: NgbDateStruct = null;
  keyword = 'name';
  rows = [];
  deliveryTypes: any[];
  phoneDetailsJsonArray: any[];
  currentSelectedDeliveryStatus: any = "All";
  currentSelectedDeliveryStatusID: any = "All";
  totalOrders: any = 0;
  confirmedOrders: any = 0;
  itemList: any = [];
  private notifier: NotifierService;

  constructor(private serviceClass: ServiceService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.deliveryTypes = [{id: "orderAdded", name: "Created Orders"}, {
      id: "dispatched",
      name: "Dispatched"
    }, {id: "delivered", name: "Delivered"}, {id: "return", name: "Returned"}];

    this.serviceClass.getAllItems().subscribe(
      data => {
        this.phoneDetailsJsonArray = data;
      }
    );
  }

  exportAsExcel(): void {
    let currentTimeAndDate = new Date();
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.formatDate(currentTimeAndDate) + " - Item Wise Report.xlsx");
  }

  formatTime(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " " + newDate.getHours() + ":" + newDate.getMinutes();
  }

  formatDate(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

  getFormattedDateOfDelivery(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.toISOString().split('T')[0];
  }

  getTheFirstCharacter(empName: any) {
    return empName.substr(0, 1).toUpperCase();
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }


  resetThePage() {
    window.location.reload();
  }

  onDateChange(date: NgbDateStruct) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);

  selectedDeliveryStatus(jsonValue: any) {
    this.currentSelectedDeliveryStatus = jsonValue["name"];
    this.currentSelectedDeliveryStatusID = jsonValue["id"];
  }

  dateFormatter(dateString: any) {
    let newDate = new Date(dateString);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
  }

  timeFormatter(dateString: any) {
    let newDate = new Date(dateString);
    return newDate.getHours() + ":" + newDate.getMinutes() + ":" + newDate.getSeconds();
  }

  routeToSingleOrderPage(orderNumber: any) {
    let newRelativeUrl = this.router.createUrlTree(["/admin/order_details"]);
    let baseUrl = window.location.href.replace(this.router.url, '');
    window.open(baseUrl + newRelativeUrl + "?orderId=" + orderNumber, '_blank');
  }

  getJsonObjectFromItemDetails(id: any) {
    for (let item of this.phoneDetailsJsonArray) {
      if (item["deviceId"] == id) {
        return item;
      }
    }
  }

  getItemName(id: any) {
    let deviceArray = this.getJsonObjectFromItemDetails(id);
    if (deviceArray != undefined) {
      return deviceArray["name"];
    } else {
      return "No Item";
    }
  }

  containsInCheckedList(array: any, deviceName) {
    for (let item of array) {
      if (item["name"] == deviceName) {
        return false;
      }
    }
    return true;
  }

  getLabelType(deliveryStatus: any) {
    if (deliveryStatus == "confirmed") {
      return "label-success confirmed";
    } else {
      return "label-danger notConfirmed";
    }
  }

  getCustomLabel(deliveryStatus: any) {
    if (deliveryStatus == "confirmed") {
      return "Confirmed";
    } else {
      return deliveryStatus;
    }
  }

  updateTable() {
    if (this.currentSelectedDeliveryStatusID == "All") {
      this.showNotification('error', 'Please set delivery type **');
    } else {
      this.totalOrders += 0;
      this.confirmedOrders += 0;
      let today = new Date();
      let tempFromDate = null;
      let tempToDate = null;

      if (this.fromDate != null) {
        let fromDateObj: Date = new Date(this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day);
        fromDateObj.setDate(fromDateObj.getDate() - 1);
        tempFromDate = fromDateObj.getFullYear() + "-" + (fromDateObj.getMonth() + 1) + "-" + fromDateObj.getDate();

        if (this.toDate == null) {
          tempToDate = this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day;
        } else {
          tempToDate = this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day;
        }
      }

      this.serviceClass.getItemWiseDetails(this.currentSelectedDeliveryStatusID, tempFromDate, tempToDate).subscribe(
        data => {
          this.rows = [...data];
          this.updateOrderAndConfirmedCounts(data);
        }
      );
    }
  }

  updateOrderAndConfirmedCounts(newArray: any) {
    this.itemList = [];

    for (let item of newArray) {
      if (this.containsInCheckedList(this.itemList, item["device1"])) {
        this.itemList.push({name: item["device1"], qty: item["numDevice1"]});
      } else {
        for (let itemTemp of this.itemList) {
          if (itemTemp["name"] == item["device1"]) {
            itemTemp["qty"] = (Number(itemTemp["qty"]) + item["numDevice1"]);
          } else {

          }
        }
      }

      if (this.containsInCheckedList(this.itemList, item["device2"])) {
        this.itemList.push({name: item["device2"], qty: item["numDevice2"]});
      } else {
        for (let itemTemp of this.itemList) {
          if (itemTemp["name"] == item["device2"]) {
            itemTemp["qty"] = (Number(itemTemp["qty"]) + item["numDevice2"]);
          } else {

          }
        }
      }

      if (this.containsInCheckedList(this.itemList, item["device3"])) {
        this.itemList.push({name: item["device3"], qty: item["numDevice3"]});
      } else {
        for (let itemTemp of this.itemList) {
          if (itemTemp["name"] == item["device3"]) {
            itemTemp["qty"] = (Number(itemTemp["qty"]) + item["numDevice3"]);
          } else {

          }
        }
      }

      if (this.containsInCheckedList(this.itemList, item["device4"])) {
        this.itemList.push({name: item["device4"], qty: item["numDevice4"]});
      } else {
        for (let itemTemp of this.itemList) {
          if (itemTemp["name"] == item["device4"]) {
            itemTemp["qty"] = (Number(itemTemp["qty"]) + item["numDevice4"]);
          } else {

          }
        }
      }

      if (this.containsInCheckedList(this.itemList, item["device5"])) {
        this.itemList.push({name: item["device5"], qty: item["numDevice5"]});
      } else {
        for (let itemTemp of this.itemList) {
          if (itemTemp["name"] == item["device5"]) {
            itemTemp["qty"] = (Number(itemTemp["qty"]) + item["numDevice5"]);
          } else {

          }
        }
      }

      for (let x = 0; x < this.itemList.length; x++) {
        if (this.itemList[x]["name"] == "") {
          this.itemList.splice((x), 1);
        }
      }

    }
  }
}

import { Component, OnInit } from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {WebappService} from '../webapp.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-manage-categories',
  templateUrl: './manage-categories.component.html',
  styleUrls: ['./manage-categories.component.css']
})
export class ManageCategoriesComponent implements OnInit {
  rows = [];
  isAdd=true;

  tempData = {
    name:"",
    slug:"",
    image:{
      id:1,
      thumbnail:"",
      original:""
    },
    productCount:0,
  };
  initialData = {
    name:"",
    slug:"",
    image:{
      id:1,
      thumbnail:"",
      original:""
    },
    productCount:0,
  }

  private notifier: NotifierService;

  constructor(private serviceClass: WebappService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.updateTableData();
  }

  updateTableData(){
    this.serviceClass.getAllCategories().subscribe(
        data => {
          this.rows = data.data;
        }
    );
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  resetThePage() {
    window.location.reload();
  }

  openWidgetForAdd() {
    this.isAdd = true;
    this.tempData = this.initialData;
    document.getElementById("dataModelBtn").click()
  }

  openWidgetForEditing(data: any) {
    this.isAdd = false;
    this.tempData = data;
    document.getElementById("dataModelBtn").click()
  }

  deleteProduct(data:any) {
    if(confirm("Do you want to delete this item? ("+data.name+")")) {
      this.serviceClass.deleteCategory(data).subscribe(
          data => {
            this.updateTableData();
            this.showNotification("success", "Deleted successfully!")
          }
      );
    }
  }

  updateChanges() {
    if(this.isAdd){
      this.serviceClass.addCategory(this.tempData).subscribe(
          data => {
            this.updateTableData();
            document.getElementById("dataModelBtnClose").click()
            this.showNotification("success", "Added successfully!")
          }
      );
    } else {
      this.serviceClass.updateCategory(this.tempData).subscribe(
          data => {
            this.updateTableData();
            document.getElementById("dataModelBtnClose").click()
            this.showNotification("success", "Updated successfully!")
          }
      );
    }
  }


  // @ts-ignore
  cloudinary = cloudinary.createUploadWidget(this.serviceClass.cloudinaryArray[Number(localStorage.getItem('imageCount')) % this.serviceClass.numOfCloudinaryAccounts], (error, result) => {
        if(result.info.thumbnail_url != null) {
          this.tempData.image.original = result.info.secure_url;
          this.tempData.image.thumbnail = result.info.secure_url;
        }
      }
  )

  uploadPrimaryImage() {
      if(localStorage.getItem('imageCount')){
          this.cloudinary.open();
          localStorage.setItem('imageCount', (Number(localStorage.getItem('imageCount')) + 1).toString())
      } else {
          localStorage.setItem('imageCount','1')
          this.cloudinary.open();
      }
  }
}

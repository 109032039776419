import { Component, OnInit } from '@angular/core';
import {ServiceService} from '../../../../../service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {JwtHelperService} from '@auth0/angular-jwt';
import {PayrollService} from '../../../payroll.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  keyword = 'name';
  rows = [];
  filteredRows = [];
  noteId: number;
  private notifier: NotifierService;

  isAdd: any=true;
  monthlyPayment: any= 0;

  sheetList: any = [];
  itemList: any = [];
  itemCount: any = 0;
  itemTypeId: any = 0;


  startDate: any;
  endDate: any;
  paymentId: any= "none";
  description: any= "";
  amount: any= 0;
  type: any= "none";
  userId: any= 0;
  empName: any= "";
  params: any= {};
  date: any;

  constructor(private serviceClass: PayrollService, private activatedRoute: ActivatedRoute, private router: Router, notifier: NotifierService) {
    this.notifier = notifier;
    this.activatedRoute.queryParams.subscribe(params => {
      this.params = params;
      this.userId = params['_id'];
      this.empName = params['name'];
    });
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.updatePayrollDetails();

    this.serviceClass.payrollGetAllItems().subscribe(
        data => {
          this.itemList = data;
        }
    );
  }

  updatePayrollDetails(): void {
    this.serviceClass.payrollGetPayment(this.userId).subscribe(
        data => {
          this.rows = data.data.sort((val1, val2) => {
            let dateA = new Date(val1.createdDate).getTime();
            let dateB = new Date(val1.createdDate).getTime();
            return dateA > dateB ? 1 : -1;
          });

          this.filteredRows = this.rows;
          document.getElementById("modelClose").click();
        }
    );
  }


  isAddition(type){
    return ["Basic-Salary", "Sunday-Allowance", "Weekend-Allowance", "NightDuty-Allowance", "Transport-Allowance", "Budgetary-Relief-Allowance", "PoyaDay-Allowance", "Over-Time", "living-cost-allowance", "Sales-Commission", "Performance-Incentive"].includes(type);
  }

  calculatePricing(){
    let finalAmount = 0;
    this.rows.map(value => {
      if(this.isAddition(value.type)){
        finalAmount += value.amount;
      } else {
        finalAmount -= value.amount;
      }
    })

    return finalAmount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }


  openItemModel(){
    this.isAdd = true;
    this.description = "";
    this.amount = 0;
    this.type = "add";
    document.getElementById("dataModelBtn").click();
  }

  addBasicSalary(){
    this.isAdd = true;
    this.description = "Monthly Payment";
    this.amount = Number(Number(this.calculate('Basic-Salary')));
    this.type = "Basic-Salary";
    document.getElementById("dataModelBtn").click();
  }

  addPayment() {
    if(this.date !== undefined) {
      if (this.userId != "none") {
        this.serviceClass.payrollPaymentAdd({
          description: this.description,
          amount: this.amount,
          type: this.type,
          userId: this.userId,
          itemTypeId: this.itemTypeId,
          itemCount: this.itemCount,
          date: this.date
        }).subscribe(
            data => {
              if (data.status) {
                this.updatePayrollDetails();
                this.description = "";
                this.amount = 0;
                this.type = "add";
              } else {
                console.log(data);
                this.showNotification("error", "An error occurred!");
              }
            }
        );
      } else {
        this.showNotification("error", "Please select user details!");
      }
    } else {
      this.showNotification("error", "Date is required!");
    }
  }


  updateEmployeeBlock(id:any, description: any, amount: any, type: any) {
    this.isAdd = false;
    this.paymentId = id;
    this.description = description;
    this.amount = amount;
    this.type = type;
    document.getElementById("dataModelBtn").click();
  }

  updateEmployeeDetails() {
    if (this.paymentId != "none") {
      this.serviceClass.payrollPaymentUpdate({id: this.paymentId, description: this.description, amount: this.amount, type: this.type, itemTypeId: this.itemTypeId, itemCount: this.itemCount, date: this.date}).subscribe(
          data => {
            if (data.status) {
              this.updatePayrollDetails();
              this.showNotification("success", "Item Updated Successfully!");
            } else {
              this.showNotification("error", "There is a error. Please check and try again!");
            }
          }
      );
    } else {
      this.showNotification("error", "Please select user details!");
    }
  }

  deletePayment(indexElement) {
    if(confirm("Do you want to delete this?")) {
      this.serviceClass.payrollPaymentDelete(indexElement).subscribe(
          data => {
            if (data.status) {
              this.showNotification("success", "Payment Deleted successfully!");
              this.updatePayrollDetails();
            } else {
              this.showNotification("error", "There is a error. Please check and try again!");
            }
          }
      );
    }
  }

  setAccordionId(index: number) {
    if(index === 0){
      this.monthlyPayment = 0;
    }
    this.noteId = index;
  }

  exportAsExcel(): void {
    let currentTimeAndDate = new Date();
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.formatDate(currentTimeAndDate) + " - All Items.xlsx");
  }


  formatDate(dateAndTime: any) {
    let newDate = new Date(dateAndTime);
    return newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate() + " " + newDate.getHours() + ":"+newDate.getMinutes();
  }

  goToPage(indexElement) {
    this.routeToSingleOrderPage(indexElement)
  }


  routeToSingleOrderPage(indexElement) {
    const windowHash = window.location.hash;
    const urlArray = windowHash.split("/");
    this.router.navigate(['/' + urlArray[1] + '/payroll/user/profile'], {queryParams: indexElement});
  }

  dateUpdate() {
    let startDateTemp = new Date(this.startDate);
    let endDateTemp = this.endDate === undefined ? new Date() :  new Date(this.endDate+"T23:59:59.542Z");
    this.filteredRows = this.rows.filter(value=> {
      let createdDate = new Date(value.date);
      return startDateTemp <= createdDate && endDateTemp >= createdDate;
    })
  }

  getRows() {
    return this.rows.length > this.filteredRows.length ? this.filteredRows : this.rows
  }

  openInvoice() {
    if(this.startDate !== undefined && this.endDate !== undefined) {
      if (this.filteredRows.filter(value => value.type === 'Basic-Salary').length <= 1) {
        let params = new URLSearchParams(Object.assign({}, this.params, {
          startDate: this.startDate,
          endDate: this.endDate
        }, this.getProperPaymentDetails())).toString();
        this.serviceClass.payrollSheetsAdd({url: '/#/multiUser/paysheet?' + params, amount: this.getProperPaymentDetails()['netSalary'], userId: this.params._id}).subscribe(
            data => {}
        );

        window.open('/#/multiUser/paysheet?' + params, "");

      } else {
        this.showNotification("error", "You cannot have two monthly payments.")
      }
    } else {
      this.showNotification("error", "Please select date range and try again.")
    }
  }

  setEpfAmount(){
    if(this.type === 'E-P-F-Employees-Contribution'){
      this.itemTypeId = "1";
      this.itemCount = this.calculate('E-P-F-Employees-Contribution');
      this.amount =  this.calculate('E-P-F-Employees-Contribution');
    }
  }


  openInvoiceWithOutBasic() {
    if(this.startDate !== undefined && this.endDate !== undefined) {
      if (this.filteredRows.filter(value => value.type === 'Basic-Salary').length <= 1) {
        let params = new URLSearchParams(Object.assign({}, this.params, {
          startDate: this.startDate,
          endDate: this.endDate
        }, this.getDailyPaymentWithOutMonthly(), {itemWiseCount: JSON.stringify(this.getItemList())})).toString();

        window.open('/#/multiUser/paysheet?' + params, "", "width=600,height=3000");

      } else {
        this.showNotification("error", "You cannot have two monthly payments.")
      }
    } else {
      this.showNotification("error", "Please select date range and try again.")
    }
  }

  getProperPaymentDetails(){
    if(this.params.type === "daily"){
      return this.getDailyPayment();
    } else if(this.params.type === "monthly"){
      return this.getMonthlyPayment();
    } else if(this.params.type === "orderWise"){
      return this.getOrderWisePayment();
    } else {
      return {};
    }
  }

  getDailyPaymentWithOutMonthly(){
    return {
      'Basic-Salary': 0,
      'Sunday-Allowance':  this.calculate('Sunday-Allowance'),
      'Weekend-Allowance':  this.calculate('Weekend-Allowance'),
      'NightDuty-Allowance':  this.calculate('NightDuty-Allowance'),
      'PoyaDay-Allowance':  this.calculate('PoyaDay-Allowance'),
      'Transport-Allowance':  this.calculate('Transport-Allowance'),
      'Budgetary-Relief-Allowance':  this.calculate('Budgetary-Relief-Allowance'),
      'living-cost-allowance':  this.calculate('living-cost-allowance'),
      'Over-Time':  this.calculate('Over-Time'),
      'Sales-Commission':  this.calculate('Sales-Commission'),
      'Performance-Incentive':  this.calculate('Performance-Incentive'),
      'E-P-F-Employees-Contribution':  this.calculate('E-P-F-Employees-Contribution'),
      'Salary-Advance':  this.calculate('Salary-Advance'),
      'Insurance':  this.calculate('Insurance'),
      'Loans':  this.calculate('Loans'),
      'Lunch':  this.calculate('Lunch'),
      'totalEarnings': (this.calculate('Transport-Allowance') + this.calculate('Budgetary-Relief-Allowance') + this.calculate('living-cost-allowance')  + this.calculate('Over-Time') + this.calculate('Sales-Commission') + this.calculate('Performance-Incentive')),
      'deductions': (this.calculate('E-P-F-Employees-Contribution') + this.calculate('Salary-Advance') + this.calculate('Insurance') + this.calculate('Loans') + this.calculate('Lunch')),
      'netSalary': (this.calculate('Transport-Allowance') + this.calculate('Budgetary-Relief-Allowance') + this.calculate('living-cost-allowance') + this.calculate('Over-Time') + this.calculate('Sales-Commission') + this.calculate('Performance-Incentive')) - (this.calculate('E-P-F-Employees-Contribution') + this.calculate('Salary-Advance') + this.calculate('Insurance') + this.calculate('Loans') + this.calculate('Lunch')),
      'salaryForEpf': Number(this.calculate('Basic-Salary')),
      'employeesContributionToEPF12': this.calculate('E-P-F-Employees-Contribution')/8*12,
      'employeesContributionToEPF3':  this.calculate('E-P-F-Employees-Contribution')/8*3
    }
  }

  getDailyPayment(){
    return {
      'Basic-Salary': this.calculate('Basic-Salary'),
      'Sunday-Allowance':  this.calculate('Sunday-Allowance'),
      'Weekend-Allowance':  this.calculate('Weekend-Allowance'),
      'NightDuty-Allowance':  this.calculate('NightDuty-Allowance'),
      'PoyaDay-Allowance':  this.calculate('PoyaDay-Allowance'),
      'Transport-Allowance':  this.calculate('Transport-Allowance'),
      'Budgetary-Relief-Allowance':  this.calculate('Budgetary-Relief-Allowance'),
      'living-cost-allowance':  this.calculate('living-cost-allowance'),
      'Over-Time':  this.calculate('Over-Time'),
      'Sales-Commission':  this.calculate('Sales-Commission'),
      'Performance-Incentive':  this.calculate('Performance-Incentive') - Number(this.calculate('Basic-Salary')),
      'E-P-F-Employees-Contribution':  this.calculate('E-P-F-Employees-Contribution'),
      'Salary-Advance':  this.calculate('Salary-Advance'),
      'Insurance':  this.calculate('Insurance'),
      'Loans':  this.calculate('Loans'),
      'Lunch':  this.calculate('Lunch'),
      'totalEarnings': (Number(this.calculate('Basic-Salary')) + this.calculate('Sunday-Allowance') + this.calculate('Weekend-Allowance') + this.calculate('NightDuty-Allowance') + this.calculate('PoyaDay-Allowance') + this.calculate('Transport-Allowance') + this.calculate('Budgetary-Relief-Allowance') + this.calculate('living-cost-allowance') + this.calculate('Over-Time') + this.calculate('Sales-Commission') + this.calculate('Performance-Incentive') - Number(this.calculate('Basic-Salary'))),
      'deductions': (this.calculate('E-P-F-Employees-Contribution') + this.calculate('Salary-Advance') + this.calculate('Insurance') + this.calculate('Loans') + this.calculate('Lunch')),
      'netSalary': (Number(this.calculate('Basic-Salary')) + this.calculate('Sunday-Allowance') + this.calculate('Weekend-Allowance') + this.calculate('NightDuty-Allowance') + this.calculate('PoyaDay-Allowance') + this.calculate('Transport-Allowance') + this.calculate('Budgetary-Relief-Allowance') + this.calculate('living-cost-allowance') + this.calculate('Over-Time') + this.calculate('Sales-Commission') + this.calculate('Performance-Incentive')  - Number(this.calculate('Basic-Salary'))) - (this.calculate('E-P-F-Employees-Contribution') + this.calculate('Salary-Advance') + this.calculate('Insurance') + this.calculate('Loans') + this.calculate('Lunch')),
      'salaryForEpf': Number(this.calculate('Basic-Salary')),
      'employeesContributionToEPF12': this.calculate('E-P-F-Employees-Contribution')/8*12,
      'employeesContributionToEPF3':  this.calculate('E-P-F-Employees-Contribution')/8*3
    }
  }

  getOrderWisePayment(){
    return {
      'Basic-Salary': this.calculate('Basic-Salary'),
      'Sunday-Allowance':  this.calculate('Sunday-Allowance'),
      'Weekend-Allowance':  this.calculate('Weekend-Allowance'),
      'NightDuty-Allowance':  this.calculate('NightDuty-Allowance'),
      'PoyaDay-Allowance':  this.calculate('PoyaDay-Allowance'),
      'Transport-Allowance':  this.calculate('Transport-Allowance'),
      'Budgetary-Relief-Allowance':  this.calculate('Budgetary-Relief-Allowance'),
      'living-cost-allowance':  this.calculate('living-cost-allowance'),
      'Over-Time':  this.calculate('Over-Time'),
      'Sales-Commission':  this.calculate('Sales-Commission'),
      'Performance-Incentive':  this.calculate('Performance-Incentive')  - Number(this.calculate('Basic-Salary')),
      'E-P-F-Employees-Contribution':  this.calculate('E-P-F-Employees-Contribution'),
      'Salary-Advance':  this.calculate('Salary-Advance'),
      'Insurance':  this.calculate('Insurance'),
      'Loans':  this.calculate('Loans'),
      'Lunch':  this.calculate('Lunch'),
      'totalEarnings': (Number(this.calculate('Basic-Salary')) + this.calculate('Sunday-Allowance') + this.calculate('Weekend-Allowance') + this.calculate('NightDuty-Allowance')  + this.calculate('PoyaDay-Allowance') + this.calculate('Transport-Allowance')+ this.calculate('Budgetary-Relief-Allowance') + this.calculate('living-cost-allowance') + this.calculate('Over-Time') + this.calculate('Sales-Commission') + this.calculate('Performance-Incentive')  - Number(this.calculate('Basic-Salary'))),
      'deductions': (this.calculate('E-P-F-Employees-Contribution') + this.calculate('Salary-Advance') + this.calculate('Insurance') + this.calculate('Loans') + this.calculate('Lunch')),
      'netSalary': (Number(this.calculate('Basic-Salary')) + this.calculate('Sunday-Allowance') + this.calculate('Weekend-Allowance') + this.calculate('NightDuty-Allowance')  + this.calculate('PoyaDay-Allowance') + this.calculate('Transport-Allowance')+ this.calculate('Budgetary-Relief-Allowance') + this.calculate('living-cost-allowance') + this.calculate('Over-Time') + this.calculate('Sales-Commission') + this.calculate('Performance-Incentive')  - Number(this.calculate('Basic-Salary'))) - (this.calculate('E-P-F-Employees-Contribution') + this.calculate('Salary-Advance') + this.calculate('Insurance') + this.calculate('Loans') + this.calculate('Lunch')),
      'salaryForEpf': Number(this.calculate('Basic-Salary')),
      'employeesContributionToEPF12': this.calculate('E-P-F-Employees-Contribution')/8 * 12,
      'employeesContributionToEPF3':  this.calculate('E-P-F-Employees-Contribution')/8* 3
    }
  }

  getMonthlyPayment(){
    return {
      'Basic-Salary': this.calculate('Basic-Salary'),
      'Sunday-Allowance':  this.calculate('Sunday-Allowance'),
      'Weekend-Allowance':  this.calculate('Weekend-Allowance'),
      'NightDuty-Allowance':  this.calculate('NightDuty-Allowance'),
      'PoyaDay-Allowance':  this.calculate('PoyaDay-Allowance'),
      'Transport-Allowance':  this.calculate('Transport-Allowance'),
      'Budgetary-Relief-Allowance':  this.calculate('Budgetary-Relief-Allowance'),
      'living-cost-allowance':  this.calculate('living-cost-allowance'),
      'Over-Time':  this.calculate('Over-Time'),
      'Sales-Commission':  this.calculate('Sales-Commission'),
      'Performance-Incentive':  this.calculate('Performance-Incentive'),
      'E-P-F-Employees-Contribution':  this.calculate('E-P-F-Employees-Contribution'),
      'Salary-Advance':  this.calculate('Salary-Advance'),
      'Insurance':  this.calculate('Insurance'),
      'Loans':  this.calculate('Loans'),
      'Lunch':  this.calculate('Lunch'),
      'totalEarnings': (Number(this.calculate('Basic-Salary')) + this.calculate('Sunday-Allowance') + this.calculate('Weekend-Allowance') + this.calculate('NightDuty-Allowance')  + this.calculate('PoyaDay-Allowance') + this.calculate('Transport-Allowance') + this.calculate('Budgetary-Relief-Allowance') + this.calculate('living-cost-allowance') + this.calculate('Over-Time') + this.calculate('Sales-Commission') + this.calculate('Performance-Incentive')),
      'deductions': (this.calculate('E-P-F-Employees-Contribution') + this.calculate('Salary-Advance')  + this.calculate('Insurance') + this.calculate('Loans') + this.calculate('Lunch')),
      'netSalary': (Number(this.calculate('Basic-Salary')) + this.calculate('Sunday-Allowance') + this.calculate('Weekend-Allowance') + this.calculate('NightDuty-Allowance')  + this.calculate('PoyaDay-Allowance') + this.calculate('Transport-Allowance') + this.calculate('Budgetary-Relief-Allowance') + this.calculate('living-cost-allowance') + this.calculate('Over-Time') + this.calculate('Sales-Commission') + this.calculate('Performance-Incentive')) - (this.calculate('E-P-F-Employees-Contribution') + this.calculate('Salary-Advance') + this.calculate('Insurance') + this.calculate('Loans') + this.calculate('Lunch')),
      'salaryForEpf': Number(this.calculate('Basic-Salary')),
      'employeesContributionToEPF12': this.calculate('E-P-F-Employees-Contribution') / 8 * 12,
      'employeesContributionToEPF3':  this.calculate('E-P-F-Employees-Contribution') / 8 * 3,
    }
  }

  calculate(keyword){
    let totalValue = 0;
    this.filteredRows.filter(value => {
      if(value.type === keyword){
        totalValue += value.amount;
      }
    })
    return totalValue > 0 ? totalValue : 0;
  }

  updatePriceOnItemChange() {
    if(this.itemTypeId === '1') {
      this.amount = this.itemCount;
    } else {
      this.amount = this.itemList.find(value => value._id === this.itemTypeId).price * this.itemCount;
    }
  }

  updateManualPriceChange() {
    this.itemTypeId = "1";
    this.itemCount = this.amount;
  }

  previousPaySheets() {
    this.serviceClass.payrollGetAllSheets(this.userId).subscribe(
        data => {
          this.sheetList =  data.data;
          window.document.getElementById("previousOrdersModel").click();
        }
    );
  }

  openPaySheet(url) {
    window.open(url, "", "width=600,height=3000");
  }

  deleteSheet(indexElement: any) {
    this.serviceClass.payrollSheetsDelete(indexElement).subscribe(
        data => {
          this.serviceClass.payrollGetAllSheets(this.userId).subscribe(
              data => {
                this.sheetList = data.data;
              }
          );
        }
    );
  }

  getItemList() {
    return this.itemList.map(value => {
      let qty = this.rows.map((paymentList) => {
        if(paymentList.itemTypeId === value._id){
          return paymentList.itemCount
        } else {
          return 0
        }
      })
      return {name: value.name, qty: qty.length > 0 ? qty.reduce((a, b) => { return a + b; }) : [] }
    })

  }

  getTotalQty(array){

  }
}

import {Component, OnInit} from '@angular/core';
import {ServiceService} from "../../service.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice_scss.component.scss']
})
export class InvoiceComponent implements OnInit {
  orderId: any;
  clientName: any;
  addressNumber: any;
  firstLine: any;
  secondLine: any;
  city: any;
  phoneNumber: any;
  telephone: any;
  nicNumber: any;
  codNumber: any;
  currentDateAndTime = new Date();
  todayDate: any;
  total: any;
  deliveryType: any;
  item1 = "Item 1";
  item2 = "Item 2";
  item3 = "Item 3";
  item4 = "Item 4";
  item5 = "Item 5";
  specialNote1 = "";
  qty1: any;
  qty2: any;
  qty3: any;
  qty4: any;
  qty5: any;
  unitPrice1: any;
  unitPrice2: any;
  unitPrice3: any;
  unitPrice4: any;
  unitPrice5: any;
  unitTotal1: any;
  unitTotal2: any;
  unitTotal3: any;
  unitTotal4: any;
  unitTotal5: any;
  extraDeliveryCost: any;
  discount: any = 0;
  allDeliveryType: any;
  phoneDetailsJsonArray = [];

  constructor(private serviceClass: ServiceService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.orderId = params['orderId'];
    });
  }

  ngOnInit() {
    this.todayDate = this.currentDateAndTime.getDate() + "/" + (this.currentDateAndTime.getMonth() + 1) + "/" + this.currentDateAndTime.getFullYear();

    this.serviceClass.getAllDeliveryTypes().subscribe(
        data => {
          this.allDeliveryType = data;
        }
    )
    this.getCurrentOrderDetails();
  }

  getCurrentOrderDetails() {
    this.serviceClass.getAllItems().subscribe(
      data => {
        this.phoneDetailsJsonArray = data;
        this.serviceClass.getSingleOrderDetails(this.orderId).subscribe(
            data => {
              if (data.length > 0) {
                this.clientName = data[0].customerName;
                this.addressNumber = data[0].addressNumber;
                this.firstLine = data[0].addressFirstLine;
                this.secondLine = data[0].addressSecondLine;
                this.city = data[0].city;
                this.phoneNumber = data[0].phoneNumber;
                this.telephone = data[0].fixedLine;
                this.nicNumber = data[0].idNumber;
                this.codNumber = data[0].COD;
                this.extraDeliveryCost = data[0].extraDeliveryCost;
                // @ts-ignore
                this.discount = data[0].discount;
                this.deliveryType = data[0].deliveryType;
                this.specialNote1 = data[0].specialNote1;
                this.item1 = this.getItemName(data[0].device1, false);
                this.item2 = this.getItemName(data[0].device2, false);
                this.item3 = this.getItemName(data[0].device3, false);
                this.item4 = this.getItemName(data[0].device4, false);
                this.item5 = this.getItemName(data[0].device5, false);
                this.qty1 = data[0].numDevice1;
                this.qty2 = data[0].numDevice2;
                this.qty3 = data[0].numDevice3;
                this.qty4 = data[0].numDevice4;
                this.qty5 = data[0].numDevice5;
                this.unitPrice1 = this.getItemName(data[0].device1, true);
                this.unitPrice2 = this.getItemName(data[0].device2, true);
                this.unitPrice3 = this.getItemName(data[0].device3, true);
                this.unitPrice4 = this.getItemName(data[0].device4, true);
                this.unitPrice5 = this.getItemName(data[0].device5, true);

                this.unitTotal1 = Number(this.unitPrice1) * Number(this.qty1);
                this.unitTotal2 = Number(this.unitPrice2) * Number(this.qty2);
                this.unitTotal3 = Number(this.unitPrice3) * Number(this.qty3);
                this.unitTotal4 = Number(this.unitPrice4) * Number(this.qty4);
                this.unitTotal5 = Number(this.unitPrice5) * Number(this.qty5);

                this.total = Number(this.unitTotal1) + Number(this.unitTotal2) + Number(this.unitTotal3) + Number(this.unitTotal4) + Number(this.unitTotal5) - this.discount + this.extraDeliveryCost;
              } else {
                this.router.navigate(['/admin/main']);
              }
            }
        );
      }
    );


  }

  getJsonObjectFromItemDetails(id: any) {
    for (let item of this.phoneDetailsJsonArray) {
      if (item["deviceId"] == id) {
        return item;
      }
    }
  }

  getItemName(id: any, price: Boolean) {
    let deviceArray = this.getJsonObjectFromItemDetails(id);
    if (deviceArray != undefined) {
      if (price) {
        return deviceArray["soldPrice"];
      } else {
        return deviceArray["name"];
      }
    } else {
      return "0";
    }
  }

  getDeliveryPrice() {
    if(this.allDeliveryType) {
      return this.allDeliveryType.filter(value => value.id === Number(this.deliveryType))[0];
    } else {
      return {price:0,"name":"Not Selected"};
    }
  }
}
